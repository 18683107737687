import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService } from 'src/app/services/graphql.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/user';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-addproject',
  templateUrl: './addproject.component.html',
  styleUrls: ['./addproject.component.css'],
})
export class AddprojectComponent implements OnInit {
  validateForm!: FormGroup;
  user_id!: string;
  userData!: User;
  userRole!: string;
  projectType = 'development';
  projetStatus = 'active';
  client_id = environment.CLIENT_ID;
  submitForm(): void {
    this.user_id = localStorage.getItem('user_id') || '';
    if (this.userRole == 'ADMIN') {
      this.gqlService
        .addProject(
          this.validateForm.value.title,
          this.user_id,
          this.projectType,
          this.projetStatus,
          this.client_id
        )
        .subscribe(
          ({ data }) => {
            // add all the admins to the mailing list
            let project_id = data.insert_task_manager_projects_one.id;
            const STEPH_ID = 'd2f2c1115ad1b79121ec659368a7b949',
              HAZEE_ID = '38018f59c9c792071e168f013f725010',
              ATHUL_ID = '230960d73ec9e5c3cc49c9da0072c024';

            let mailing_list = [
              {
                project_id,
                user_id: STEPH_ID,
              },
              {
                project_id,
                user_id: HAZEE_ID,
              },
              {
                project_id,
                user_id: ATHUL_ID,
              },
            ];

            this.gqlService.addMembers(mailing_list).subscribe((data) => {
              // console.log(data);
            });

            this.msg.success('Project Added', { nzPauseOnHover: true });
          },
          (error) => {
            this.msg.error('Error while adding Project', {
              nzPauseOnHover: true,
            });
          }
        );
    } else {
      this.gqlService
        .createProject(
          this.validateForm.value.title,
          this.user_id,
          this.projectType,
          this.projetStatus,
          this.client_id
        )
        .subscribe(
          ({ data }) => {
            // add all the admins to the mailing list
            let project_id = data.insert_task_manager_projects_one.id;
            const STEPH_ID = 'd2f2c1115ad1b79121ec659368a7b949',
              HAZEE_ID = '38018f59c9c792071e168f013f725010',
              ATHUL_ID = '230960d73ec9e5c3cc49c9da0072c024';

            let mailing_list = [
              {
                project_id,
                user_id: STEPH_ID,
              },
              {
                project_id,
                user_id: HAZEE_ID,
              },
              {
                project_id,
                user_id: ATHUL_ID,
              },
            ];

            this.gqlService.addMembers(mailing_list).subscribe((data) => {
              // console.log(data);
            });

            this.msg.success('Project Submitted for Approval', {
              nzPauseOnHover: true,
            });
          },
          (error) => {
            this.msg.error('Error while adding Project', {
              nzPauseOnHover: true,
            });
          }
        );
    }
  }

  constructor(
    private fb: FormBuilder,
    private gqlService: GraphqlService,
    private msg: NzMessageService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.userData = <User>JSON.parse(localStorage.getItem('user')!);
    this.userRole = this.userData.role;
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
    });
  }
  //back button
  back() {
    localStorage.setItem('projectBack', 'true');
    // this.location.back();
    this.router.navigateByUrl('home');
  }
}
