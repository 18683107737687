<div class="container-box">
  <h3 [ngStyle]="{ margin: '16px 0', color: 'royalblue' }">
    REJECTED PROJECTS
  </h3>
  <ul nz-list [nzDataSource]="rejected" nzBordered nzSize="large">
    <li class="my-2" nz-list-item *ngFor="let item of rejected" nzNoFlex>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <i nz-icon nzType="close-circle" nzTheme="outline"></i>
        </nz-list-item-action>
      </ul>
      {{ item.title }}
    </li>
    <li></li>
  </ul>
</div>
