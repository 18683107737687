<div class="container">
  <ng-container>
    <div class="row">
      <div class="col-md-6 col-lg-3 my-2">
        <nz-card class="card">
          <div id="item" class="media align-items-center">
            <i
              nz-icon
              style="
                background-color: #49a4f9;
                color: white;
                font-size: 35px;
                float: left;
              "
              nzType="project"
              nzTheme="outline"
            ></i>
            <div class="m-l-15">
              <h2 style="float: right" class="m-t-0">{{ projectCount }}</h2>
              <p style="float: left" class="m-b-0 text-muted">Projects</p>
            </div>
          </div>
        </nz-card>
      </div>
      <div class="col-md-6 col-lg-3 my-2">
        <nz-card class="card">
          <div routerLink="" id="item" class="media align-items-center">
            <i
              nz-icon
              style="
                background-color: #3cf749;
                color: white;
                font-size: 35px;
                float: left;
              "
              nzType="schedule"
              nzTheme="outline"
            ></i>
            <div class="m-l-15">
              <h2 style="float: right" class="m-t-0">{{ closedTasks }}</h2>
              <p style="float: left" class="m-b-0 text-muted">Closed Tasks</p>
            </div>
          </div>
        </nz-card>
      </div>
      <div class="col-md-6 col-lg-3 my-2">
        <nz-card class="card">
          <div id="item" class="media align-items-center">
            <i
              nz-icon
              style="
                background-color: #f8bf3a;
                color: white;
                font-size: 35px;
                float: left;
              "
              nzType="redo"
              nzTheme="outline"
            ></i>

            <div class="m-l-15">
              <h2 style="float: right" class="m-t-0">{{ reopenedTasks }}</h2>
              <p style="float: left" class="m-b-0 text-muted">Reopened Tasks</p>
            </div>
          </div>
        </nz-card>
      </div>
      <div class="col-md-6 col-lg-3 my-2">
        <nz-card class="card">
          <div id="item" class="media align-items-center">
            <i
              nz-icon
              style="
                background-color: #f35a3b;
                color: white;
                font-size: 35px;
                float: left;
              "
              nzType="close-square"
              nzTheme="outline"
            ></i>

            <div class="m-l-15">
              <h2 style="float: right" class="m-t-0">{{ rejectedTasks }}</h2>
              <p style="float: left" class="m-b-0 text-muted">Rejected Tasks</p>
            </div>
          </div>
        </nz-card>
      </div>
      <div class="col-md-6 col-lg-3 my-2">
        <nz-card class="card">
          <div id="item" class="media align-items-center">
            <i
              nz-icon
              style="
                background-color: #18ffec;
                color: white;
                font-size: 35px;
                float: left;
              "
              nzType="up-square"
              nzTheme="outline"
            ></i>
            <div class="m-l-15">
              <h2 style="float: right" class="m-t-0">{{ websiteLaunch }}</h2>
              <p style="float: left" class="m-b-0 text-muted">Website Launch</p>
            </div>
          </div>
        </nz-card>
      </div>
      <div class="col-md-6 col-lg-3 my-2">
        <nz-card class="card">
          <div id="item" class="media align-items-center">
            <i
              nz-icon
              style="
                background-color: #7c74ee;
                color: white;
                font-size: 35px;
                float: left;
              "
              nzType="calculator"
              nzTheme="outline"
            ></i>

            <div class="m-l-15">
              <h2 style="float: right" class="m-t-0">{{ billable }}</h2>
              <p style="float: left" class="m-b-0 text-muted">Billable</p>
            </div>
          </div>
        </nz-card>
      </div>
      <div class="col-md-6 col-lg-3 my-2">
        <nz-card class="card">
          <div id="item" class="media align-items-center">
            <i
              nz-icon
              style="
                background-color: #1890ff;
                color: white;
                font-size: 35px;
                float: left;
              "
              nzType="profile"
              nzTheme="outline"
            ></i>
            <div class="m-l-15">
              <h2 style="float: right" class="m-t-0">{{ overdue }}</h2>
              <p style="float: left" class="m-b-0 text-muted">Tasks Overdue</p>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
  </ng-container>
  <!-- <ng-container>
    <form
      class="graph-form"
      nz-form
      [formGroup]="graphForm"
      (ngSubmit)="submit()"
    >
      <h5>Select Graph Range</h5>
      <ng-container class="form-inputs">
        <nz-form-item class="form-item">
          <nz-form-label nzRequired> From </nz-form-label>
          <nz-form-control [nzErrorTip]="fromError">
            <nz-date-picker formControlName="from"></nz-date-picker>
            <ng-template #fromError let-control>
              <ng-container *ngIf="control.hasError('required')">
                Please choose a date
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item class="form-item">
          <nz-form-label nzRequired> To </nz-form-label>
          <nz-form-control [nzErrorTip]="toError">
            <nz-date-picker formControlName="to"></nz-date-picker>
            <ng-template #toError let-control>
              <ng-container *ngIf="control.hasError('required')">
                Please choose a date
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <nz-form-item>
        <nz-form-control [nzOffset]="7" [nzSpan]="12">
          <button nz-button nzType="primary" [disabled]="!graphForm.valid">
            Submit
          </button>
          <button nz-button (click)="resetForm($event)">Reset</button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <div id="chart">
    <canvas id="myChart"></canvas>
  </div> -->
</div>
