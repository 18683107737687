import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem('token');
    const user = <User>JSON.parse(localStorage.getItem('user')!);
    let Role: string;
    switch (user?.role) {
      case 'ADMIN':
        Role = 'admin';
        break;
      case 'BEETLER':
        Role = 'employee';
        break;
      case 'STUDYMEDICIAN':
        Role = 'client';
        break;

      default:
        Role = 'employee';
        break;
    }
    if (token && Role) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          // 'x-hasura-admin-secret': environment.hasuraSecret,
          'x-hasura-role': Role,
        },
      });
    }
    return next.handle(request);
  }
}
