<i
id="back"
(click)="backArrow()"
nz-icon
nzType="arrow-left"
nzTheme="outline"
></i>
<div class="container1">

  <nz-card [nzTitle]="userData.name" [nzExtra]="extraTemplate">
    <p>Phone Number : {{ userData.phone_number }}</p>
    <p>Role : {{ userData.role }}</p>
    <p>Email : {{ userData.email }}</p>
    <button nz-button [nzSize]="size" nzType="primary">
      <a routerLink="edit">Edit Profile</a>
    </button>
  </nz-card>
  <ng-template #extraTemplate>
    <a> <nz-avatar [nzSize]="64" nzIcon="user"></nz-avatar></a>
  </ng-template>
</div>
