import { Routes } from '@angular/router';
import { AddClientComponent } from '../pages/add-client/add-client.component';
import { AddEmployeeComponent } from '../pages/add-employee/add-employee.component';
import { AddprojectComponent } from '../pages/addproject/addproject.component';
import { EditProfileComponent } from '../pages/edit-profile/edit-profile.component';
import { ContentComponent } from '../pages/content/content.component';
import { HomeComponent } from '../pages/home/home.component';
import { NewtaskComponent } from '../pages/newtask/newtask.component';
import { PendingsComponent } from '../pages/pendings/pendings.component';
import { RejectedComponent } from '../pages/rejected/rejected.component';
import { SingleTaskComponent } from '../pages/single-task/single-task.component';
import { TasksComponent } from '../pages/tasks/tasks.component';
import { UserProfileComponent } from '../pages/user-profile/user-profile.component';
import { RoleGuard } from '../guards/role.guard';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { PendingActionsComponent } from '../pages/pending-actions/pending-actions.component';

export const COMMON_LAYOUT_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path:'dashboard',
    canActivate:[RoleGuard],
    component:DashboardComponent,
  },
  {
    path: 'add-employee',
    canActivate:[RoleGuard],
    component: AddEmployeeComponent,
  },
  {
    path: 'add-client',
    component: AddClientComponent,
  },
  {
    path: 'add-project',
    component: AddprojectComponent,
  },
  {
    path: 'pendings',
    component: PendingsComponent,
  },
  {
    path: 'rejected',
    canActivate:[RoleGuard],
    component: RejectedComponent,
  },
  {
    path: 'profile',
    children: [
      {
        path: '',
        component: UserProfileComponent,
      },
      {
        path: 'edit',
        component: EditProfileComponent,
      },
    ],
  },
  {
    path: 'tasks/:id',
    children: [
      {
        path: '',
        component: TasksComponent,
      },
      {
        path: 'newTask',
        component: NewtaskComponent,
      },
      {
        path: ':tid',
        component: SingleTaskComponent,
      },
    ],
  },
  {
    path:'pendingActions',
    children:[
      {
        path:'',
        component:PendingActionsComponent
      },
      {
        path:':tid',
        component:SingleTaskComponent,
      }
    ]
    
  },
  {
    path: 'tasksList/:action',
    children: [
      {
        path: '',
        component: ContentComponent,
      },
      {
        path: ':tid',
        component: SingleTaskComponent,
      },
     
    ],
  },
];
