<div class="wrapper">
  <ul
    style="height: 100%"
    nz-menu
    nzMode="inline"
    nzTheme="dark"
    [nzInlineCollapsed]="isCollapsed"
  >
    <!-- <button nz-button nzType="primary" (click)="toggleCollapsed()">
            <i nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </button> -->
        <li
        *ngIf="userRole == 'ADMIN'"
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Dashboard' : ''"
        routerLink="dashboard"
      >
      <i nz-icon nzType="dashboard" nzTheme="outline"></i>
        <span>Dashboard</span>
      </li>
    <li nz-submenu nzTitle="Projects" nzIcon="appstore">
      <ul *ngFor="let project of projectData">
        <li
          routerLink="tasks/{{ project.id }}"
          [state]="{ title: project.title }"
          nz-menu-item
        >
          {{ project.title }}
        </li>

        <!-- <li nz-submenu nzTitle="Submenu">
            <ul>
              <li nz-menu-item>Option 7</li>
              <li nz-menu-item>Option 8</li>
            </ul>
          </li> -->
      </ul>
    </li>
    <li
      *ngIf="userRole == 'ADMIN'"
      nz-menu-item
      nz-tooltip
      nzTooltipPlacement="right"
      [nzTooltipTitle]="isCollapsed ? 'Navigation One' : ''"
      routerLink="pendings"
    >
      <i nz-icon nzType="question-circle" nzTheme="outline"></i>
      <span>Pending Approvals</span>
    </li>
    <li
      nz-menu-item
      nz-tooltip
      nzTooltipPlacement="right"
      routerLink="rejected"
    >
      <i nz-icon nzType="close-circle" nzTheme="outline"></i>
      <span>Rejected Projects</span>
    </li>
    <!-- saved filters -->
    <!-- 
    <li nz-submenu nzTitle="Saved Filters" nzIcon="setting">
      <ul *ngFor="let filter of filterData">
        <li
          routerLink="tasks/{{ filter.project_id }}"
          [state]="{
            title: filter.project.title,
            filter: {
              title: filter.title,
              status: filter.status,
              priority: filter.priority
            }
          }"
          nz-menu-item
        >
          {{ filter.name }}
        </li>
      </ul>
    </li> -->

    <li nz-submenu nzTitle="Tasks" nzIcon="appstore">
      <ul *ngFor="let item of data">
        <li routerLink="tasksList/{{ item.action }}" nz-menu-item>
          {{ item.action }}
        </li>
       
      </ul>
      
    </li>
    <li routerLink="pendingActions" nz-menu-item>
      Pending Actions 
    </li>
  </ul>
</div>
