<div class="container-box">
  <i
  id="back"
  (click)="backArrow()"
  nz-icon
  nzType="arrow-left"
  nzTheme="outline"
></i>
    <h3 [ngStyle]="{ margin: '16px 0', color: 'royalblue' }">TASKS WITH PENDING ACTION</h3>
    <ul
      nz-list
      [nzDataSource]="taskData"
      *ngFor="let item of taskData"
      nzBordered
      nzSize="large"
    >
      <li routerLink="{{ item.id }}" class="my-2" nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <i nz-icon nzType="exclamation-circle" style="color: rgb(212, 212, 7); font-size: 20px;" nzTheme="outline"></i>
          </nz-list-item-action>
        </ul>
        {{ item.title }}
      </li>
      <li></li>
    </ul>
    <div class="empty">
      <nz-empty *ngIf="taskData.length == 0"></nz-empty>
    </div>
  </div>