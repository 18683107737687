import { Component, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { Subscription } from 'rxjs';
import { GraphqlService } from 'src/app/services/graphql.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  graphForm!: FormGroup;
  private countSubscription!: Subscription;
  projectCount = 0;
  closedTasks = 0;
  rejectedTasks = 0;
  reopenedTasks = 0;
  billable = 0;
  overdue = 0;
  websiteLaunch = 0;
  options: any;

  constructor(private graphql: GraphqlService, private fb: FormBuilder) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    // graph form
    this.graphForm = this.fb.group({
      from: ['', [Validators.required]],
      to: ['', [Validators.required]],
    });

    this.getCounts();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // backgroundColor: [
    //         'rgba(255, 99, 132, 0.2)',
    //         'rgba(54, 162, 235, 0.2)',
    //         'rgba(255, 206, 86, 0.2)',
    //         'rgba(75, 192, 192, 0.2)',
    //         'rgba(153, 102, 255, 0.2)',
    //         'rgba(255, 159, 64, 0.2)',
    //       ],
    //       borderColor: [
    //         'rgba(255, 99, 132, 1)',
    //         'rgba(54, 162, 235, 1)',
    //         'rgba(255, 206, 86, 1)',
    //         'rgba(75, 192, 192, 1)',
    //         'rgba(153, 102, 255, 1)',
    //         'rgba(255, 159, 64, 1)',
    //       ],

    const myChart = new Chart('myChart', {
      type: 'bar',
      data: {
        labels: months,
        datasets: [
          {
            label: 'Website Launch',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['rgba(255, 99, 132, 0.2)'],
            borderColor: ['rgba(255, 99, 132, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Billable',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['rgba(54, 162, 235, 0.2)'],
            borderColor: ['rgba(54, 162, 235, 1)'],
            borderWidth: 1,
          },
          {
            label: 'Tasks Overdue',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['rgba(255, 206, 86, 0.2)'],
            borderColor: ['rgba(255, 206, 86, 1)'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.graphForm.reset();
    for (const key in this.graphForm.controls) {
      if (this.graphForm.controls.hasOwnProperty(key)) {
        this.graphForm.controls[key].markAsPristine();
        this.graphForm.controls[key].updateValueAndValidity();
      }
    }
  }
  getCounts() {
    this.countSubscription = this.graphql.getCounts().subscribe(({ data }) => {
      this.projectCount = data.projects.aggregate.count;
      this.closedTasks = data.closed.aggregate.count;
      this.rejectedTasks = data.rejected.aggregate.count;
      this.reopenedTasks = data.reopened.aggregate.count;
      this.billable = data.billable.aggregate.count;
      this.overdue = data.overdue.aggregate.count;
      this.websiteLaunch = data.launch.aggregate.count;
    });
  }

  submit() {
    console.log(this.graphForm.value);
  }
}
