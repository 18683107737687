<div class="container-box">
<h3 [ngStyle]="{ margin: '16px 0' ,color:'royalblue'}">PENDING APPROVALS</h3>
    <ul nz-list [nzDataSource]="unApproved" nzBordered nzSize="large">
      <li class="my-2" nz-list-item *ngFor="let item of unApproved" nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a (click)="approve(item.id)">Approve</a>
          </nz-list-item-action>
          <nz-list-item-action>
            <a (click)="reject(item.id)">Reject</a>
          </nz-list-item-action>
        </ul>
        {{ item.title }}
      </li>
      <li></li>
    </ul>
</div>
