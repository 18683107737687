import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DeAuthGuard } from './guards/de-auth.guard';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { COMMON_LAYOUT_ROUTES } from './routes/common-layout.routes';

const routes: Routes = [
  // for all routes except login
  {
    path: '',
    component: CommonLayoutComponent,
    children: COMMON_LAYOUT_ROUTES,
    canActivate: [AuthGuard],
  },
  // login route
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [DeAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
