import { gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';
const CLIENT_ID = environment.CLIENT_ID;

export const Queries = {
  // to get user data
  getUser: gql`
    query getUser($external_id: String) {
      task_manager_users(where: { external_id: { _eq: $external_id } }) {
        name
        email
        phone_number
        external_id
        role
      }
    }
  `,
  //get dropdowns
  getDropdowns: gql`
    query getDropdowns {
      task_manager_priority {
        priority
      }
      task_manager_status {
        status
      }
      task_manager_actions {
        action
      }
    }
  `,
  //get employees
  getEmployee: gql`
    query getEmployee {
      task_manager_users(where: { role: { _eq: BEETLER } }) {
        name
        external_id
      }
    }
  `,
  //get comments
  getComments: gql`
    query getComments($taskId: uuid) {
      task_manager_comments(
        where: { task_id: { _eq: $taskId } }
        order_by: { created_at: desc }
      ) {
        comment
        created_at
        is_sh
        url
        is_studymedic
        user {
          name
        }
      }
    }
  `,

  // get latest beetle comment
  getSHComment: gql`
    query getSHComment($taskId: uuid) {
      task_manager_comments(
        where: { task_id: { _eq: $taskId }, is_sh: { _eq: true } }
        order_by: { created_at: desc }
        limit: 1
      ) {
        comment
        created_at
        url
        user {
          name
        }
      }
    }
  `,
  // get latest beetle comment
  getAthulComment: gql`
    query getAthulComment($taskId: uuid) {
      task_manager_comments(
        where: { task_id: { _eq: $taskId }, is_studymedic: { _eq: true } }
        order_by: { created_at: desc }
        limit: 1
      ) {
        comment
        created_at
      }
    }
  `,
  // get all projects
  getProjects: gql`
    query getProjects($limit: Int, $offset: Int) {
      task_manager_projects(
        where: { isAproved: { _eq: true }, client_id: {_eq: "${CLIENT_ID}"} }
        limit: $limit
        offset: $offset
      ) {
        title
        id
        user_id
        created_at
        production_release
      }
      task_manager_projects_aggregate(where: { isAproved: { _eq: true } , client_id: {_eq: "${CLIENT_ID}"}}) {
        aggregate {
          count
        }
      }
    }
  `,
  //fetch users
  fetchUsers: gql`
    query fetchUsers {
      task_manager_users {
        external_id
        name
      }
    }
  `,
  //fetch user based on role
  fetchUserList: gql`
    query fetchUserList($role: task_manager_roles_enum) {
      task_manager_users(where: { role: { _eq: $role } }) {
        external_id
        name
      }
    }
  `,
  //search users
  searchUsers: gql`
    query searchUsers($name: String) {
      task_manager_users(where: { name: { _like: $name } }) {
        external_id
        name
      }
    }
  `,
  //search projects
  searchProjects: gql`
    query searchProjects($title: String) {
      task_manager_projects(
        where: { isAproved: { _eq: true }, title: { _ilike: $title }, client_id: {_eq: "${CLIENT_ID}"} }
      ) {
        id
        title
      }
    }
  `,
  //search project
  searchProject: gql`
    query searchProject($id: uuid) {
      task_manager_projects(where: { id: { _eq: $id } }) {
        title
        id
        user_id
        created_at
        production_release
      }
    }
  `,

  //get unapproved projects
  getUnapproved: gql`
    query getProjects {
      task_manager_projects(
        where: { isAproved: { _eq: false }, IsRejected: { _eq: false }, client_id: {_eq: "${CLIENT_ID}"} }
      ) {
        title
        id
        user_id
        created_at
      }
    }
  `,
  //get rejected projects
  getRejected: gql`
    query getRejected {
      task_manager_projects(
        where: { isAproved: { _eq: false }, IsRejected: { _eq: true }, client_id: {_eq: "${CLIENT_ID}"} }
      ) {
        title
        id
        user_id
        created_at
      }
    }
  `,
  //get export data
  getExportData: gql`
    query getExportData {
      task_manager_tasks(order_by: { created_at: desc }) {
        title
        description
        link
        status
        priority
        is_billable
        due_date
        assigned_to
        assignee {
          name
        }
        project {
          title
        }
        user {
          name
        }
      }
    }
  `,
  // get task data
  getTasks: gql`
    query getTasks(
      $where: task_manager_tasks_bool_exp = {}
      $limit: Int
      $offset: Int
    ) {
      task_manager_tasks(
        where: $where
        limit: $limit
        offset: $offset
        order_by: { created_at: desc }
      ) {
        id
        title
        status
        priority
        is_closed
        due_date
        estimated_date
        assigned_to
        action
        display_date
        assignee {
          name
        }
        project {
          title
        }
      }
      task_manager_tasks_aggregate(where: $where) {
        aggregate {
          count
        }
      }
    }
  `,
  //get tasks based on action
  getTasksList: gql`
    query getTasksList($action: task_manager_actions_enum) {
      task_manager_tasks(where: { action: { _eq: $action } }) {
        id
        title
        status
        priority
        is_closed
        due_date
        assigned_to
        assignee {
          name
        }
        project {
          title
        }
      }
    }
  `,
  //get single task details
  getTask: gql`
    query getTask($taskId: uuid) {
      task_manager_tasks(where: { id: { _eq: $taskId } }) {
        assigned_to
        comment_id
        is_billable
        billable_time
        created_at
        comments {
          comment
          is_sh
          created_at
          is_studymedic
          user {
            name
          }
        }
        description
        due_date
        estimated_date
        link
        priority
        status
        action
        title
        is_closed
        project_id
        project {
          title
        }
      }
    }
  `,

  // get status count for projects
  getStatusCount: gql`
    query getStatusCount($project_id: uuid, $status: task_manager_status_enum) {
      task_manager_tasks_aggregate(
        where: { project_id: { _eq: $project_id }, status: { _eq: $status } }
      ) {
        aggregate {
          count(columns: id)
        }
      }

      rejected: task_manager_tasks_aggregate(
        where: { project_id: { _eq: $project_id }, action: { _eq: Rejected } }
      ) {
        aggregate {
          count(columns: id)
        }
      }
    }
  `,
  // get all status
  getStatus: gql`
    query getStatus {
      task_manager_status {
        status
      }
    }
  `,

  // get all saved filters
  getFilters: gql`
    query getFilers($user_id: String, $project_id: uuid) {
      task_manager_filters(
        where: { user_id: { _eq: $user_id }, project_id: { _eq: $project_id } }
      ) {
        name
        project_id
        title
        priority
        status
        project {
          title
        }
        assigned_to
      }
    }
  `,
  //get project name
  getProjectName: gql`
    query getTasks($projectId: uuid) {
      task_manager_projects(where: { id: { _eq: $projectId } }) {
        id
        title
      }
    }
  `,
  // get activity log
  getLogs: gql`
    query getLogs($task_id: uuid) {
      task_manager_activity_log(
        where: { task_id: { _eq: $task_id } }
        order_by: { created_at: desc }
      ) {
        activity_name
        created_at
        user {
          name
        }
      }
    }
  `,
  //get Dsahboard Counts
  getCounts: gql`
    query getCounts {
      projects: task_manager_projects_aggregate(
        where: { isAproved: { _eq: true } , client_id: {_eq: "${CLIENT_ID}"}}
      ) {
        aggregate {
          count
        }
      }
      closed: task_manager_tasks_aggregate(where: {action: {_eq: Closed}, project: {client_id: {_eq: "${CLIENT_ID}"}}}) {
        aggregate {
          count
        }
      }
      reopened: task_manager_tasks_aggregate(
        where: { action: { _eq: Reopened }, project: {client_id: {_eq: "${CLIENT_ID}"}} }
      ) {
        aggregate {
          count
        }
      }
      rejected: task_manager_tasks_aggregate(
        where: { action: { _eq: Rejected }, project: {client_id: {_eq: "${CLIENT_ID}"}} }
      ) {
        aggregate {
          count
        }
      }
      billable: task_manager_tasks_aggregate(
        where: { is_billable: { _eq: true }, project: {client_id: {_eq: "${CLIENT_ID}"}} }
      ) {
        aggregate {
          count
        }
      }
      overdue: task_manager_tasks_aggregate(
        where: { status: { _eq: DateExceeded }, project: {client_id: {_eq: "${CLIENT_ID}"}} }
      ) {
        aggregate {
          count
        }
      }
      launch: task_manager_website_launch_aggregate {
        aggregate {
          count
        }
      }
    }
  `,
  // get all display dates
  fetchDisplayDates: gql`
    query fetchDisplayDates {
      task_manager_tasks(
        distinct_on: display_date
        order_by: { display_date: desc }
      ) {
        display_date
      }
    }
  `,
  //get Project Members
  getMembers: gql`
    query getMembers($projetcId: uuid, $role: task_manager_roles_enum) {
      task_manager_mailing_list(
        where: {
          project_id: { _eq: $projetcId }
          user: { role: { _eq: $role } }
        }
      ) {
        user {
          name
          external_id
        }
      }
    }
  `,

  // getGraphData: gql`
  //   query getGraphData {
  //     overdue: task_manager_tasks_aggregate(
  //       where: {
  //         created_at: { _gte: "2022-06-20", _lte: "2022-07-12" }
  //         status: { _eq: DateExceeded }
  //       }
  //     ) {
  //       aggregate {
  //         count
  //       }
  //     }
  //     website_launch: task_manager_tasks_aggregate(
  //       where: {
  //         created_at: { _gte: "2022-06-20", _lte: "2022-07-12" }
  //         action: { _eq: Website_Launch }
  //       }
  //     ) {
  //       aggregate {
  //         count
  //       }
  //     }
  //     is_billable: task_manager_tasks_aggregate(
  //       where: {
  //         is_billable: { _eq: true }
  //         created_at: { _gte: "2022-06-20", _lte: "2022-07-12" }
  //       }
  //     ) {
  //       aggregate {
  //         count
  //       }
  //     }
  //   }
  // `,
};
