import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/user';
import { GraphqlService } from 'src/app/services/graphql.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  size: NzButtonSize = 'large';
  projectData = new Array();
  status = ['Completed', 'InProgress'];
  date = null;
  isClicked = false;
  userData!: User;
  userRole!: string;
  heading!: any;
  back!: any;
  projectId!: any;
  indexChange = false;
  totalCount = 0;
  selectedValue = null;
  isOpen = false;
  isBatchButtonLoading!: boolean;
  listOfOption: Array<{ value: string; text: string }> = [];
  listOfUsers: Array<{ value: string; label: string; checked: boolean }> = [];
  memberList: Array<{ project_id: any; user_id: any }> = [];
  deleteList: Array<{ user_id: any }> = [];
  addList = new Array();
  tempId: any;
  isAthul = false;
  isAdmin = false;
  fetchRole!: string;
  ids = new Array();
  nzFilterOption = () => true;
  userList = [];
  queryParams = {
    limit: 6,
    offset: 0,
  };
  private projectSubscription!: Subscription;
  constructor(private graphql: GraphqlService, private msg: NzMessageService) {}

  ngOnInit(): void {
    this.userData = <User>JSON.parse(localStorage.getItem('user')!);
    this.userRole = this.userData.role;
    if (this.userRole == 'ADMIN') {
      this.isAdmin = true;
    }
    if (this.userData.external_id == environment.ATHUL_ID) {
      this.isAthul = true;
    }
    this.fetchProjects();
  }

  fetchProjects() {
    this.projectData = [];
    // get projects and loop
    this.projectSubscription = this.graphql
      .getProjectsHome(this.queryParams)
      .subscribe(({ data }) => {
        this.isBatchButtonLoading = false;
        this.totalCount = data.task_manager_projects_aggregate.aggregate.count;
        data.task_manager_projects.forEach((project: any, index: any) => {
          let title = data.task_manager_projects[index].title;
          let id = data.task_manager_projects[index].id;
          let releaseDate =
            data.task_manager_projects[index].production_release;
          let projectCount = {
            id: '',
            title: '',
            production_release: '',
            Completed: 0,
            InProgress: 0,
            Rejected: 0,
          };
          projectCount.title = title;
          projectCount.id = id;
          projectCount.production_release = releaseDate;
          // get status count for each status
          this.status.forEach((stat) => {
            this.graphql
              .getStatusCount(project.id, stat)
              .subscribe((count: any) => {
                projectCount.Rejected = count.data.rejected.aggregate.count;

                if (stat == 'Completed')
                  projectCount.Completed =
                    count.data.task_manager_tasks_aggregate.aggregate.count;

                if (stat == 'InProgress')
                  projectCount.InProgress =
                    count.data.task_manager_tasks_aggregate.aggregate.count;
              });
          });
          //  project data with status count
          this.projectData.push(projectCount);
        });
      });
  }
  gridStyle = {
    width: '250px',
    textAlign: 'center',
    height: '250px',
  };
  addDate(id: any, title: any) {
    this.projectId = id;
    this.isClicked = true;
    this.heading = title;
  }
  cancel() {
    this.isClicked = false;
  }
  reset() {
    this.projectData = [];
    this.selectedValue = null;
    this.indexChange = false;
    this.searchQuiz();
  }
  pageIndexChanged(pageIndex: any): void {
    localStorage.setItem('pageindex', pageIndex);
    if (this.indexChange) {
      return;
    }
    this.queryParams.offset = (pageIndex - 1) * this.queryParams.limit;
    this.projectData = [];
    this.fetchProjects();
  }
  // search
  search(value: string): void {
    this.graphql.searchProjects(value).subscribe(({ data, loading }) => {
      const listOfOption: Array<{ value: string; text: string }> = [];
      data.task_manager_projects.forEach((item: any) => {
        listOfOption.push({
          value: item.id,
          text: item.title,
        });
      });
      this.listOfOption = listOfOption;
    });
  }
  searchQuiz() {
    if (this.selectedValue == null || this.selectedValue == '') {
      this.isBatchButtonLoading = true;
      this.fetchProjects();
      return;
    } else {
      this.isBatchButtonLoading = true;
      this.graphql.searchProject(this.selectedValue).subscribe((data) => {
        this.projectData = [];
        // this.totalCount = 1;
        data.data.task_manager_projects.forEach((project: any, index: any) => {
          let title = data.data.task_manager_projects[index].title;
          let id = data.data.task_manager_projects[index].id;
          let releaseDate =
            data.data.task_manager_projects[index].production_release;
          let projectCount = {
            id: '',
            title: '',
            production_release: '',
            Completed: 0,
            InProgress: 0,
            Rejected: 0,
          };
          projectCount.title = title;
          projectCount.id = id;
          projectCount.production_release = releaseDate;
          // get status count for each status
          this.status.forEach((stat) => {
            this.graphql.getStatusCount(project.id, stat).subscribe((count) => {
              if (stat == 'Completed')
                projectCount.Completed =
                  count.data.task_manager_tasks_aggregate.aggregate.count;
              if (stat == 'Rejected')
                projectCount.Rejected =
                  count.data.task_manager_tasks_aggregate.aggregate.count;
              if (stat == 'InProgress')
                projectCount.InProgress =
                  count.data.task_manager_tasks_aggregate.aggregate.count;
            });
          });
          this.isBatchButtonLoading = false;
          this.indexChange = true;
          //  project data with status count
          this.projectData.push(projectCount);
          return;
        });
        this.isBatchButtonLoading = false;
      });
    }
  }
  update() {
    let queryParams = {
      where: {
        id: {
          _eq: this.projectId,
        },
      },
      _set: {
        production_release: null,
      },
    };
    if (this.date == null) {
      this.msg.error('Choose a date', { nzPauseOnHover: true });
      return;
    } else {
      queryParams._set.production_release = this.date;
      this.graphql.addDate(queryParams).subscribe(
        (data) => {
          this.projectData = [];
          this.isClicked = false;
          this.msg.success('Production release date updated successfully', {
            nzPauseOnHover: true,
          });
        },
        (error) => {
          this.isClicked = false;
          this.msg.error('Error while updating production release date', {
            nzPauseOnHover: true,
          });
        }
      );
    }
  }
  //listing users for adding to project
  showMembers(projectId: string) {
    if (this.userRole == 'ADMIN') {
      this.fetchRole = 'BEETLER';
    }
    if (this.isAthul) {
      this.fetchRole = 'STUDYMEDICIAN';
    }

    this.tempId = projectId;
    console.log(projectId);

    this.graphql.getMembers(projectId, this.fetchRole).subscribe((data) => {
      let length = data.data.task_manager_mailing_list.length;
      if (length == 0) {
        this.graphql
          .fetchUserList(this.fetchRole)
          .subscribe(({ data, loading }) => {
            const listOfOption: Array<{
              value: string;
              label: string;
              checked: boolean;
            }> = [];
            data.task_manager_users.forEach((item: any) => {
              listOfOption.push({
                value: item.external_id,
                label: item.name,
                checked: false,
              });
            });
            this.listOfUsers = listOfOption;
            console.log(this.listOfUsers);
          });
      } else {
        this.ids = data.data.task_manager_mailing_list.map(
          (user: any) => user.user.external_id
        );
        this.graphql
          .fetchUserList(this.fetchRole)
          .subscribe(({ data, loading }) => {
            const listOfOption: Array<{
              value: string;
              label: string;
              checked: boolean;
            }> = [];
            data.task_manager_users.forEach((item: any) => {
              if (this.ids.includes(item.external_id)) {
                listOfOption.push({
                  value: item.external_id,
                  label: item.name,
                  checked: true,
                });
              } else {
                listOfOption.push({
                  value: item.external_id,
                  label: item.name,
                  checked: false,
                });
              }
            });
            this.listOfUsers = listOfOption;
          });

        console.log(this.ids);
      }
    });
    this.isOpen = true;
  }
  close() {
    this.isOpen = false;
  }

  check() {}
  addMembers() {
    if (this.memberList.length != 0) {
      this.graphql.addMembers(this.memberList).subscribe(
        (data) => {
          console.log(data);
          this.msg.success('Member list upadted', { nzPauseOnHover: true });
          this.isOpen = false;
        },
        (error) => {
          this.msg.error('Error while updating', { nzPauseOnHover: true });
        }
      );
    }
    if (this.deleteList.length != 0) {
      this.graphql.removeMembers(this.deleteList, this.tempId).subscribe(
        (data) => {
          console.log(data);
          this.msg.success('Member list upadted', { nzPauseOnHover: true });
          this.isOpen = false;
        },
        (error) => {
          this.msg.error('Error while updating', { nzPauseOnHover: true });
        }
      );
    }
  }
  checked(user: any, item: any) {
    if (item == true) {
      if (this.deleteList.includes(user.value)) {
        let index = this.deleteList.indexOf(user.value);
        this.deleteList.splice(index, 1);
      }
      let temp = {
        project_id: this.tempId,
        user_id: user.value,
      };
      this.addList.push(user.value);
      if (!this.ids.includes(user.value)) this.memberList.push(temp);
    } else if (item == false) {
      let temp = {
        project_id: this.tempId,
        user_id: user.value,
      };

      if (this.addList.includes(user.value)) {
        let index = this.memberList.indexOf(temp);
        console.log('index', index);

        this.memberList.splice(index, 1);
      }
      if (this.ids.includes(user.value)) this.deleteList.push(user.value);
    }
    console.log('deleted', this.deleteList, 'added', this.memberList);
  }
  ngOnDestroy(): void {
    this.projectSubscription.unsubscribe();
  }
}
