import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GraphqlService } from 'src/app/services/graphql.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-pending-actions',
  templateUrl: './pending-actions.component.html',
  styleUrls: ['./pending-actions.component.css'],
})
export class PendingActionsComponent implements OnInit {
  taskData= new Array();
  private subscription!:Subscription;
  constructor(
    private graphql:GraphqlService,
    private location:Location
    ) {}

  ngOnInit(): void {
   

      let query = {
        where: {
          action: { _is_null: true },
          status: {
            _in: ['Completed', 'NotDoable', 'IssueNotFound'],
          },
        },
      };
   this.subscription= this.graphql.getTasks(query).subscribe((data)=>{
      console.log(data);
      this.taskData=data.data.task_manager_tasks;
    })
    
  }
  backArrow(){
    this.location.back()
  }
}
