import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/user';
import { GraphqlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SidenavComponent implements OnInit {
  isCollapsed = false;
  projectData = new Array();
  userData!: User;
  userRole!: string;
  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  constructor(private graphql: GraphqlService) {}

  ngOnInit(): void {
    this.userData = <User>JSON.parse(localStorage.getItem('user')!);
    this.userRole = this.userData.role;
    this.graphql.getProjects().subscribe((data) => {
      this.projectData = data.data.task_manager_projects;
    });
  }
  data = [
    { action: 'Closed' },
    { action: 'Rejected' },
    { action: 'Reopened' },
    { action: 'Website_Launch' },
    { action: 'Phase_2' },
  ];
}
