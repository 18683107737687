import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { GraphqlService } from 'src/app/services/graphql.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { taskBody } from 'src/app/interfaces/task.type';
import { task_manager_priority_enum } from 'src/app/interfaces/priority.enum';
import { task_manager_status_enum } from 'src/app/interfaces/status.enum';
import { User } from 'src/app/interfaces/user';
import { NzPlacementType } from 'ng-zorro-antd/dropdown';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit, OnDestroy {
  dropPosition: NzPlacementType[] = ['bottomLeft'];
  title!: string;
  size: NzButtonSize = 'large';
  id!: any;
  taskData = new Array();
  dateLen!: number;
  dataLen: number = 0;
  showFilter = false;
  filterForm!: FormGroup;
  priorityList = new Array();
  statusList = new Array();
  isLegends = false;
  back!: any;
  totaltasks = 0;
  userData!: User;
  userId!: string;
  userRole!: string;
  private datesubscription!: Subscription;
  private taskSubscription!: Subscription;
  savedFilters = new Array();
  isLoading: boolean = true;
  queryParams: taskBody = {
    limit: 7,
    offset: 0,
    where: {
      project_id: {
        _eq: '',
      },
    },
  };
  priority!: task_manager_priority_enum;
  status!: task_manager_status_enum;
  noData!: boolean;
  displayDates = new Array();
  employeeList = new Array();
  projectName!: string;
  constructor(
    private route: ActivatedRoute,
    private graphql: GraphqlService,
    private fb: FormBuilder,
    private message: NzMessageService,
    private dataService: DataService,
    private location:Location
  ) { }

  ngOnInit(): void {
    this.back = localStorage.getItem('back');
    if (this.back == 'true') {
      localStorage.removeItem('back');
      location.reload();
    }
    this.id = this.route.snapshot.paramMap.get('id') || '';

    this.fetchDisplayDates();

    //filter form
    this.filterForm = this.fb.group({
      title: [''],
      priority: [''],
      status: [''],
      assignee: [''],
      filterName: [''],
    });

    this.graphql.fetchDropdown().subscribe(({ data }) => {
      this.statusList = data.task_manager_status;
      this.priorityList = data.task_manager_priority;
    });

    // get all employee list
    this.graphql.getEmployee().subscribe(({ data }) => {
      this.employeeList = data.task_manager_users;
    });
  }

  fetchDisplayDates() {
    this.route.params.subscribe((params) => {
      this.isLoading = true;
      // get project id
      this.id = this.route.snapshot.paramMap.get('id') || '';
      this.graphql.getProjectName(this.id).subscribe((data)=>{

this.projectName=data.data.task_manager_projects[0].title;
console.log(this.projectName);
      })
      //get userDetails
      this.userData = <User>JSON.parse(localStorage.getItem('user')!);
      this.userRole = this.userData.role;
      this.userId = this.userData.external_id;

      this.taskData.length = 0;
      this.dateLen = 0;
      this.dataLen = 0;
      this.datesubscription = this.graphql
        .fetchDisplayDates()
        .subscribe(({ data }) => {
          this.dateLen = data.task_manager_tasks.length;
          if (this.dateLen == 0) {
            this.isLoading = false;
            this.noData = true;
          }
          this.displayDates = data.task_manager_tasks;
          data.task_manager_tasks.forEach((date: any, index: number) => {
            let where = {
              display_date: {
                _eq: date.display_date,
              },
              status: {
                _nin: ['Completed', 'NotDoable', 'IssueNotFound'],
              },
            };
            // get task data for each date
            this.getTasks(date.display_date, index, where);
          });
        });

      // get saved filters

      this.graphql.getFilters(this.userId, this.id).subscribe(({ data }) => {
        this.savedFilters = data.task_manager_filters;
      });
    });
  }

  getTasks(date: any, index: number, where: {}) {
    // this.noData = false;
    this.noData = true;
    let dispDate = date;

    let query: taskBody = {
      limit: 7,
      offset: 0,
      where: where,
    };

    query.where!.project_id = { _eq: this.id };

    this.taskSubscription = this.graphql
      .getTasks(query)
      .subscribe(({ data }) => {
        
        let dateObj = {
          date: dispDate,
          tasks: [...data.task_manager_tasks],
        };

        this.dataLen += data.task_manager_tasks.length;

        this.taskData[index] = dateObj;

        // for last date iteration
        if (index == this.dateLen - 1) {
          if (this.dataLen == 0) {
            this.isLoading = false;
            this.noData = true;
          } else {
            this.noData = false;
            this.isLoading = false;
          }
        }
      });
  }

  resetForm(): void {
    this.queryParams.where = { project_id: { _eq: this.id } };
    this.filterForm.reset();
  }
  toggleFilter() {
    this.showFilter = !this.showFilter;
  }
  showLegends() {
    this.isLegends = !this.isLegends;
  }

  export(): void {
    this.graphql.getExportData().subscribe((data) => {
      const filteredData = data.data.task_manager_tasks.map((row: any) => {
        return {
          'Project Name': row.project.title,
          'Task Name': row.title,
          'Created By': row.user.name,
          Description: row.description,
          Proirity: row.priority,
          Status: row.status,
          'Assigned To': row.assignee?.name,
          Link: row.link,
          'Due Date': row.due_date,
          'Is billable': row.is_billable,
        };
      });
      this.dataService.exportAsExcelFile(filteredData, 'tasks');
    });
  }

  search() {
    this.priority = this.filterForm.value.priority;
    let title = this.filterForm.value.title;
    this.status = this.filterForm.value.status;
    let assigned_to = this.filterForm.value.assignee;
    this.searchQuery(this.priority, this.status, title, assigned_to);
  }

  // apply saved filter
  applyFilter(index: number) {
    let priority: task_manager_priority_enum;
    let status: task_manager_status_enum;
    priority = this.savedFilters[index].priority;
    status = this.savedFilters[index].status;
    let title = this.savedFilters[index].title;
    let assigned_to = this.savedFilters[index].assigned_to;

    this.searchQuery(priority, status, title, assigned_to);
  }
  //fetch my tasks
  myTasks() {
    this.dataLen = 0;

    this.displayDates.forEach((date: any, index: number) => {
      let queryParams: taskBody = {
        limit: 7,
        offset: 0,
        where: {
          project_id: {
            _eq: '',
          },
        },
      };
      queryParams.where = { assigned_to: { _eq: this.userId } };
      this.getTasks(date.display_date, index, queryParams.where);
    });
  }
  // save fileter
  saveFilter() {
    if (
      (this.filterForm.value.title ||
        this.filterForm.value.status ||
        this.filterForm.value.priority ||
        this.filterForm.value.assignee) &&
      this.filterForm.value.filterName
    ) {
      let filter_obj = {
        name: this.filterForm.value.filterName,
        user_id: this.userId,
        project_id: this.id,
        title: this.filterForm.value.title ? this.filterForm.value.title : null,
        status: this.filterForm.value.status
          ? this.filterForm.value.status
          : null,
        priority: this.filterForm.value.priority
          ? this.filterForm.value.priority
          : null,
        assigned_to: this.filterForm.value.assignee
          ? this.filterForm.value.assignee
          : null,
      };

      this.graphql.createFilter(filter_obj).subscribe((data) => {
        this.message.success('Filter saved successfully');
      });
    }
  }

  // generate query params for filter query
  searchQuery(
    priority: task_manager_priority_enum,
    status: task_manager_status_enum,
    title: string,
    assigned_to: string
  ) {
    // generate query parameter based on search filter
    this.dataLen = 0;

    this.displayDates.forEach((date: any, index: number) => {
      let queryParams: taskBody = {
        limit: 7,
        offset: 0,
        where: {
          project_id: { _eq: '' },
        },
      };

      queryParams.where = { display_date: { _eq: date.display_date } };

      if (priority)
        queryParams.where!.priority = {
          _eq: priority,
        };
      if (status)
        queryParams.where!.status = {
          _eq: status,
        };
      if (title)
        queryParams.where!.title = {
          _ilike: `%${title}%`,
        };

      if (assigned_to)
        queryParams.where!.assigned_to = {
          _eq: assigned_to,
        };

      this.getTasks(date.display_date, index, queryParams.where);
    });
  }
  backArrow(){
    this.location.back()
  }
  ngOnDestroy(): void {
    // this.datesubscription.unsubscribe();
    // this.taskSubscription.unsubscribe();
  }
}
