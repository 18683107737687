import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService } from 'src/app/services/graphql.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css'],
})
export class ContentComponent implements OnInit {
  action!: any;
  taskData = new Array();
  title!: String;
  constructor(
    private route: ActivatedRoute, 
    private graphql: GraphqlService,
    private location:Location
    ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.action = this.route.snapshot.paramMap.get('action') || '';
      this.graphql.getTasksList(this.action).subscribe((data) => {
        this.taskData = data.data.task_manager_tasks;
        switch (this.action) {
          case 'Closed':
            this.title = 'CLOSED TASKS';
            break;
          case 'Rejected':
            this.title = 'REJECTED TASKS';
            break;
          case 'Reopened':
            this.title = 'REOPENED TASKS';
            break;
          case 'Website_Launch':
            this.title = 'UPCOMING LAUNCHES';
            break;
          case 'Phase_2':
            this.title = 'PHASE-2 TASKS';
            break;
          default:
            this.title = 'TASKS';
            break;
        }
      });
    });
  }
  backArrow(){
    this.location.back()
  }
}
