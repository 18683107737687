<div class="formContainer">
  <div class="secondContainer">
    
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
      <i id="back" (click)="back()"  nz-icon nzType="arrow-left" nzTheme="outline"></i>
      <h3 id="log-heading">CREATE PROJECT</h3>
     
      <br />
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input Project Name!">
          <nz-input-group>
            <input
              class="control"
              formControlName="title"
              nz-input
              placeholder="Project Name"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button nz-button nzType="primary" [disabled]="!validateForm.valid">
            Create Project
          </button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
