import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { Observable, Subscription } from 'rxjs';
import { Queries } from '../APIs/queries.graphql';
import { Mutations } from '../APIs/mutations.graphql';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(private apollo: Apollo) {}
  //get dashboard counts
  getCounts() {
    return this.apollo.watchQuery<any>({
      query: Queries.getCounts,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  // get user data
  getUser(external_id: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getUser,
      variables: {
        external_id,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  //Add new project by admin
  addProject(
    title: string,
    user_id: string,
    type: string,
    status: string,
    client_id: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addProject,
      variables: {
        title,
        user_id,
        type,
        status,
        client_id,
      },
      refetchQueries: [
        {
          query: Queries.getProjects,
        },
      ],
    });
  }
  //Insert website launch
  websiteLaunch(projectId: string, userId: string) {
    return this.apollo.mutate<any>({
      mutation: Mutations.websiteLaunch,
      variables: {
        projectId,
        userId,
      },
    });
  }
  // create a new project
  createProject(
    title: string,
    user_id: string,
    type: string,
    status: string,
    client_id: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createProject,
      variables: {
        title,
        user_id,
        type,
        status,
        client_id,
      },
      refetchQueries: [
        {
          query: Queries.getProjects,
        },
      ],
    });
  }
  //add comments
  addComment(
    comment: string,
    url: string,
    is_sh: boolean,
    is_studymedic: boolean,
    taskId: string,
    userId: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addComment,
      variables: {
        comment,
        url,
        is_sh,
        is_studymedic,
        taskId,
        userId,
      },
      refetchQueries: [
        {
          query: Queries.getComments,
          variables: {
            taskId,
          },
        },
        {
          query: Queries.getSHComment,
          variables: {
            taskId,
          },
        },
        {
          query: Queries.getAthulComment,
          variables: {
            taskId,
          },
        },
      ],
    });
  }
  //create new task
  createTask(
    description: string,
    dueDate: string,
    user_id: string,
    link: string,
    priority: string,
    projectId: string,
    title: string,
    display_date: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createTask,
      variables: {
        description,
        dueDate,
        user_id,
        link,
        priority,
        projectId,
        title,
        display_date,
      },
    });
  }
  //get dropdowns
  fetchDropdown() {
    return this.apollo.watchQuery<any>({
      query: Queries.getDropdowns,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  //get employee
  getEmployee() {
    return this.apollo.watchQuery<any>({
      query: Queries.getEmployee,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  //get single task
  getTask(taskId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getTask,
      variables: {
        taskId,
      },
    }).valueChanges;
  }
  //get Comments
  getComments(taskId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getComments,
      variables: {
        taskId,
      },
    }).valueChanges;
  }

  // get latest comment from Athul
  getAthulComment(taskId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getAthulComment,
      variables: {
        taskId,
      },
    }).valueChanges;
  }

  // get latest comment from Beetle
  getSHComment(taskId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getSHComment,
      variables: {
        taskId,
      },
    }).valueChanges;
  }

  // get all projects
  getProjects() {
    return this.apollo.watchQuery<any>({
      query: Queries.getProjects,
    }).valueChanges;
  }

  //get all project to home
  getProjectsHome(variables: any) {
    return this.apollo.watchQuery<any>({
      query: Queries.getProjects,
      variables,
    }).valueChanges;
  }
  //fetch users
  fetchUsers() {
    return this.apollo.watchQuery<any>({
      query: Queries.fetchUsers,
    }).valueChanges;
  }
  //fetch users based on role
  fetchUserList(role: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.fetchUserList,
      variables: {
        role,
      },
    }).valueChanges;
  }
  //search users
  searchUsers(name: string) {
    return this.apollo.query<any>({
      query: Queries.searchUsers,
      variables: {
        name: `%${name}%`,
      },
    });
  }
  //search projects
  searchProjects(title: string) {
    return this.apollo.query<any>({
      query: Queries.searchProjects,
      variables: {
        title: `%${title}%`,
      },
    });
  }
  //search project
  searchProject(id: string) {
    return this.apollo.query<any>({
      query: Queries.searchProject,
      variables: {
        id,
      },
    });
  }
  //get pending projects
  getUnapproved() {
    return this.apollo.watchQuery<any>({
      query: Queries.getUnapproved,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }
  //get rejected
  getRejected() {
    return this.apollo.watchQuery<any>({
      query: Queries.getRejected,
    }).valueChanges;
  }
  // give approval
  approve(id: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: Mutations.approve,
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: Queries.getUnapproved,
        },
        {
          query: Queries.getProjects,
        },
      ],
    });
  }
  //reject project
  reject(id: String) {
    return this.apollo.mutate<any>({
      mutation: Mutations.reject,
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: Queries.getUnapproved,
        },
      ],
    });
  }
  // get task data
  getTasks(variables: any) {
    return this.apollo.watchQuery<any>({
      query: Queries.getTasks,
      variables,
    }).valueChanges;
  }
  // get task based on action
  getTasksList(action: String) {
    return this.apollo.watchQuery<any>({
      query: Queries.getTasksList,
      variables: {
        action,
      },
    }).valueChanges;
  }
  //get ExportData
  getExportData() {
    return this.apollo.watchQuery<any>({
      query: Queries.getExportData,
    }).valueChanges;
  }
  // get status count for projects
  getStatusCount(project_id: string, status: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getStatusCount,
      variables: {
        project_id,
        status,
      },
    }).valueChanges;
  }

  // get all status
  getStatus() {
    return this.apollo.watchQuery<any>({
      query: Queries.getStatus,
    }).valueChanges;
  }

  // update Task
  updateTask(variables: any, taskId: string) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateTask,
      variables,
      refetchQueries: [
        {
          query: Queries.getTask,
          variables: {
            taskId,
          },
        },
      ],
    });
  }
  //update production release date
  addDate(variables: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addDate,
      variables,
      refetchQueries: [
        {
          query: Queries.getProjects,
        },
      ],
    });
  }
  // create a new filter
  createFilter(objects: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createFilter,
      variables: { objects },
    });
  }

  // get all filters
  getFilters(user_id: String, project_id: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getFilters,
      variables: {
        user_id,
        project_id,
      },
    }).valueChanges;
  }
  // getProjects
  getProjectName(projectId: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getProjectName,
      variables: {
        projectId,
      },
    }).valueChanges;
  }
  // create activiy log
  createLog(objects: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.createLog,
      variables: {
        objects,
      },
    });
  }
  //Add project members
  addMembers(objects: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addMembers,
      variables: {
        objects,
      },
      refetchQueries: [
        {
          query: Queries.getProjects,
        },
      ],
    });
  }
  //Remove member
  removeMembers(deleteList: Array<any>, projetcId: string) {
    return this.apollo.mutate<any>({
      mutation: Mutations.removeMembers,
      variables: {
        deleteList,
        projetcId,
      },
      refetchQueries: [
        {
          query: Queries.getProjects,
        },
      ],
    });
  }
  //add tags
  // addTags(objects: any, taskId: string) {
  //   return this.apollo.mutate<any>({
  //     mutation: Mutations.addTags,
  //     variables: {
  //       objects,
  //     },
  //     refetchQueries: [
  //       {
  //         query: Queries.getComments,
  //         variables: {
  //           taskId,
  //         },
  //       },
  //       {
  //         query: Queries.getSHComment,
  //         variables: {
  //           taskId,
  //         },
  //       },
  //       {
  //         query: Queries.getAthulComment,
  //         variables: {
  //           taskId,
  //         },
  //       },
  //     ],
  //   });
  // }

  // get activiy log
  getLogs(task_id: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getLogs,
      variables: {
        task_id,
      },
    }).valueChanges;
  }

  // get all Display dates
  fetchDisplayDates() {
    return this.apollo.watchQuery<any>({
      query: Queries.fetchDisplayDates,
    }).valueChanges;
  }
  //get Project members
  getMembers(projetcId: string, role: string) {
    return this.apollo.watchQuery<any>({
      query: Queries.getMembers,
      variables: {
        projetcId,
        role,
      },
    }).valueChanges;
  }
}
