// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // nodeServer: 'http://localhost:3010/',
  nodeServer: 'https://devl-smnode.b-tle.com/',
  hasuraSecret: '8H8aNGza0imi6',
  graphql: 'https://devl-ms-middleware.b-tle.com/v1/graphql',
  ATHUL_ID: 'e8d8e1413b5ad1b7ab46273adf29c4c5',
  AJITH_ID: '945038d10622a101abd5a68638c1b3c0',
  CLIENT_ID: 'c46387e4-9af3-436f-bd4b-764ed8644ef0',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
