<div class="reg-container">
  <div class="sign-box">
    <div class="sec-container">
      <i
        id="back"
        (click)="back()"
        nz-icon
        nzType="arrow-left"
        nzTheme="outline"
      ></i>
      <form nz-form [formGroup]="editForm" (ngSubmit)="submitForm()">
        <h3 id="log-heading">EDIT PROFILE</h3>
        <br />
        <nz-form-item>
          <nz-form-label [nzSpan]="7">Name</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback>
            <input
              class="controls"
              nz-input
              formControlName="name"
              placeholder="full name"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7">E-mail</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="emailErrorTpl"
          >
            <input
              class="controls"
              nz-input
              formControlName="email"
              placeholder="email"
              type="email"
            />
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.hasError('pattern')"
                >The input is not valid E-mail!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7">Phone Number</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="phoneErrorTpl"
          >
            <input
              class="controls"
              nz-input
              formControlName="phone_number"
              placeholder="phone number"
            />
            <ng-template #phoneErrorTpl let-control>
              <ng-container *ngIf="control.hasError('pattern')"
                >Invalid Phone number!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7">Old Password</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback>
            <nz-input-group [nzSuffix]="suffixIconOld">
              <input
                class="controls"
                placeholder="Enter old password"
                nz-input
                type="{{ showOld ? 'text' : 'password' }}"
                formControlName="oldPassword"
              />
            </nz-input-group>
            <ng-template #suffixIconOld>
              <i
                nz-icon
                *ngIf="showOld"
                (click)="showOldPass()"
                nzType="eye"
                nzTheme="outline"
              ></i>
              <i
                nz-icon
                *ngIf="!showOld"
                (click)="showOldPass()"
                nzType="eye-invisible"
                nzTheme="outline"
              ></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="editForm.controls['oldPassword'].value">
          <nz-form-label [nzSpan]="7">New Password</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            nzErrorTip="Please enter your new password!"
          >
            <nz-input-group [nzSuffix]="suffixIconSearch">
              <input
                class="controls"
                placeholder="Enter new password"
                nz-input
                type="{{ show ? 'text' : 'password' }}"
                formControlName="newPassword"
                (ngModelChange)="validateConfirmPassword()"
              />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <i
                nz-icon
                *ngIf="show"
                (click)="showPass()"
                nzType="eye"
                nzTheme="outline"
              ></i>
              <i
                nz-icon
                *ngIf="!show"
                (click)="showPass()"
                nzType="eye-invisible"
                nzTheme="outline"
              ></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="editForm.controls['newPassword'].value">
          <nz-form-label [nzSpan]="7">Confirm Password</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="passwordErrorTpl"
          >
            <nz-input-group [nzSuffix]="suffixIconShow">
              <input
                class="controls"
                nz-input
                type="{{ confmPass ? 'text' : 'password' }}"
                formControlName="confirmNewPassword"
                placeholder="confirm your password"
              />
            </nz-input-group>
            <ng-template #suffixIconShow>
              <i
                nz-icon
                *ngIf="confmPass"
                (click)="showCfmPass()"
                nzType="eye"
                nzTheme="outline"
              ></i>
              <i
                nz-icon
                *ngIf="!confmPass"
                (click)="showCfmPass()"
                nzType="eye-invisible"
                nzTheme="outline"
              ></i>
            </ng-template>
            <ng-template #passwordErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >Please confirm your password!</ng-container
              >
              <ng-container *ngIf="control.hasError('confirm')"
                >Password is inconsistent!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <div *ngIf="isError" class="err">
          <p>{{ errorMsg }}</p>
        </div>

        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button nzType="primary">Submit</button>
            <button nz-button (click)="resetForm($event)">Reset</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>
