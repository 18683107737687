<div class="reg-container">
  <div class="sign-box">
    <div class="sec-container">
      <i
        id="back"
        (click)="back()"
        nz-icon
        nzType="arrow-left"
        nzTheme="outline"
      ></i>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <h3 id="log-heading">ADD TASK</h3>
        <br />

        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Title</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            nzValidatingTip="Validating..."
            [nzErrorTip]="userErrorTpl"
          >
            <input
              class="controls"
              nz-input
              formControlName="title"
              placeholder="title"
            />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >Please input title of the task!</ng-container
              >
              <ng-container *ngIf="control.hasError('duplicated')"
                >The username is redundant!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Description</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzErrorTip="Please write something here!"
          >
            <quill-editor
              [modules]="modules"
              nz-input
              formControlName="description"
              placeholder="write description"
              [styles]="{ height: '100px' }"
            ></quill-editor>

            <!-- <textarea
              class="controls"
              formControlName="description"
              nz-input
              rows="2"
              placeholder="write description"
            ></textarea> -->
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7">*&nbsp;Link</nz-form-label>
          <nz-form-control [nzSpan]="12" nzHasFeedback>
            <input
              class="controls"
              nz-input
              formControlName="link"
              placeholder="link"
              type="email"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Priority</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            nzErrorTip="Please Choose priority"
          >
            <nz-select
              (ngModelChange)="call()"
              nzPlaceHolder="Choose"
              formControlName="priority"
            >
              <nz-option
                *ngFor="let data of priorityList"
                [nzValue]="data.priority"
                [nzLabel]="data.priority"
              ></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="showDuedate">
          <nz-form-label [nzSpan]="7" nzRequired>Due Date</nz-form-label>
          <nz-form-control [nzSpan]="12">
            <nz-date-picker
              style="width: 100%"
              formControlName="datePicker"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button nzType="primary" [disabled]="!validateForm.valid">
              Submit
            </button>
            <button nz-button (click)="resetForm($event)">Reset</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>
