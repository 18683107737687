import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GraphqlService } from './graphql.service';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from '../interfaces/user';

const CLIENT_ID = environment.CLIENT_ID;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  nodeServer = environment.nodeServer;
  hasuraSecret = environment.hasuraSecret;
  userData!: User;
  userRole!: string;
  constructor(
    private http: HttpClient,
    private gqlService: GraphqlService,
    private router: Router,
    private msg: NzMessageService
  ) {}

  // regiser a user

  register(
    name: String,
    email: String,
    phone_number: String,
    password: String,
    role: String
  ) {
    return this.http
      .post<any>(`${this.nodeServer}signup`, {
        name,
        email,
        password,
        phone_number,
        role,
        clientId: CLIENT_ID,
      })
      .subscribe(
        (data) => {
          this.msg.success('Registration Successful', { nzPauseOnHover: true });
        },
        (error) => {
          this.msg.error('Error while registering', { nzPauseOnHover: true });
        }
      );
  }

  // logs in a user and saves token and user data

  login(email: String, password: String) {
    return this.http.post<any>(`${this.nodeServer}login`, { email, password });
  }

  // checks if user is logged in

  isLoggedIn() {
    let user = localStorage.getItem('user');
    if (user) return true;
    return false;
  }

  // edit users profile
  editProfile(body: {}) {
    return this.http.post<any>(`${this.nodeServer}edit-profile`, body);
  }
  //send update notification
  sendMail(body: {}) {
    return this.http.post<any>(`${this.nodeServer}sendMail`, body);
  }
  //send comment notification
  sendComment(body: {}) {
    return this.http.post<any>(`${this.nodeServer}sendComment`, body);
  }
  //check role
  isAdmin() {
    this.userData = <User>JSON.parse(localStorage.getItem('user')!);
    this.userRole = this.userData.role;
    if (this.userRole == 'ADMIN') {
      return true;
    } else {
      return false;
    }
  }
}
