import { gql } from 'apollo-angular';

export const Mutations = {
  // to create new project
  createProject: gql`
    mutation createProject(
      $title: String
      $user_id: String
      $type: task_manager_project_type_enum
      $status: task_manager_project_status_enum
      $client_id: uuid
    ) {
      insert_task_manager_projects_one(
        object: {
          title: $title
          user_id: $user_id
          type: $type
          status: $status
          client_id: $client_id
        }
      ) {
        id
      }
    }
  `,
  //add Project by admin
  addProject: gql`
    mutation addProject(
      $title: String
      $user_id: String
      $type: task_manager_project_type_enum
      $status: task_manager_project_status_enum
      $client_id: uuid
    ) {
      insert_task_manager_projects_one(
        object: {
          title: $title
          user_id: $user_id
          isAproved: true
          type: $type
          status: $status
          client_id: $client_id
        }
      ) {
        id
      }
    }
  `,
  //add website launch
  websiteLaunch: gql`
    mutation websiteLaunch($projectId: uuid, $userId: String) {
      insert_task_manager_website_launch_one(
        object: { project_id: $projectId, user_id: $userId }
      ) {
        id
      }
    }
  `,
  //to add comments
  addComment: gql`
    mutation addComment(
      $comment: String
      $url: String
      $is_sh: Boolean
      $is_studymedic: Boolean
      $taskId: uuid
      $userId: String
    ) {
      insert_task_manager_comments(
        objects: {
          comment: $comment
          is_sh: $is_sh
          is_studymedic: $is_studymedic
          task_id: $taskId
          user_id: $userId
          url: $url
        }
      ) {
        returning {
          id
        }
      }
    }
  `,
  //approve projects
  approve: gql`
    mutation approve($id: uuid) {
      update_task_manager_projects(
        _set: { isAproved: true }
        where: { id: { _eq: $id } }
      ) {
        affected_rows
      }
    }
  `,
  //reject project
  reject: gql`
    mutation reject($id: uuid) {
      update_task_manager_projects(
        _set: { IsRejected: true }
        where: { id: { _eq: $id } }
      ) {
        affected_rows
      }
    }
  `,
  //to create task
  createTask: gql`
    mutation createTask(
      $description: String
      $dueDate: timestamptz
      $user_id: String
      $link: String
      $priority: task_manager_priority_enum
      $projectId: uuid
      $title: String
      $display_date: date
    ) {
      insert_task_manager_tasks(
        objects: {
          description: $description
          due_date: $dueDate
          external_id: $user_id
          link: $link
          priority: $priority
          project_id: $projectId
          title: $title
          display_date: $display_date
        }
      ) {
        affected_rows
        returning {
          id
          display_date
        }
      }
    }
  `,

  // update task

  updateTask: gql`
    mutation updateTask(
      $_set: task_manager_tasks_set_input = {}
      $where: task_manager_tasks_bool_exp! = {}
    ) {
      update_task_manager_tasks(where: $where, _set: $_set) {
        returning {
          priority
          status
          action
          link
          is_closed
          due_date
          assigned_to
        }
      }
    }
  `,
  addDate: gql`
    mutation addDate(
      $_set: task_manager_projects_set_input = {}
      $where: task_manager_projects_bool_exp! = {}
    ) {
      update_task_manager_projects(where: $where, _set: $_set) {
        returning {
          id
        }
      }
    }
  `,
  // create a new filter
  createFilter: gql`
    mutation createFilter($objects: [task_manager_filters_insert_input!]!) {
      insert_task_manager_filters(objects: $objects) {
        affected_rows
      }
    }
  `,
  //addTags
  // addTags: gql`
  //   mutation addTags($objects: [task_manager_tags_insert_input!]!) {
  //     insert_task_manager_tags(objects: $objects) {
  //       affected_rows
  //     }
  //   }
  // `,
  // insert activity log
  createLog: gql`
    mutation createLog($objects: [task_manager_activity_log_insert_input!]!) {
      insert_task_manager_activity_log(objects: $objects) {
        affected_rows
      }
    }
  `,
  //insert project members
  addMembers: gql`
    mutation createLog($objects: [task_manager_mailing_list_insert_input!]!) {
      insert_task_manager_mailing_list(objects: $objects) {
        affected_rows
      }
    }
  `,

  //remove project members
  removeMembers: gql`
    mutation MyMutation($deleteList: [String!], $projetcId: uuid) {
      delete_task_manager_mailing_list(
        where: {
          project_id: { _eq: $projetcId }
          user_id: { _in: $deleteList }
        }
      ) {
        affected_rows
      }
    }
  `,
};
