import { Component, OnInit } from '@angular/core';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { User } from 'src/app/interfaces/user';
import { GraphqlService } from 'src/app/services/graphql.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit {
  userData!: User;
  constructor(
    private graphql: GraphqlService,
    private location:Location
    ) {}

  ngOnInit(): void {
    this.userData = <User>JSON.parse(localStorage.getItem('user')!);
  }
  backArrow(){
    this.location.back()
  }
  size: NzButtonSize = 'small';
}
