<div class="reg-container">
  <div class="sign-box">
    <div class="sec-container">
      <i
        id="back"
        (click)="back()"
        nz-icon
        nzType="arrow-left"
        nzTheme="outline"
      ></i>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <h3 id="log-heading">ADD EMPLOYEE</h3>
        <br />
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Name</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            nzValidatingTip="Validating..."
            [nzErrorTip]="userErrorTpl"
          >
            <input
              class="controls"
              nz-input
              formControlName="name"
              placeholder="full name"
            />
            <ng-template #userErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >Please input your username!</ng-container
              >
              <ng-container *ngIf="control.hasError('duplicated')"
                >The username is redundant!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>E-mail</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="emailErrorTpl"
          >
            <input
              class="controls"
              nz-input
              formControlName="email"
              placeholder="email"
              type="email"
            />
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.hasError('pattern')"
                >The input is not valid E-mail!</ng-container
              >
              <ng-container *ngIf="control.hasError('required')"
                >Please input your E-mail!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7">Phone Number</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="phoneErrorTpl"
          >
            <input
              class="controls"
              nz-input
              formControlName="phone_number"
              placeholder="phone number"
            />
            <ng-template #phoneErrorTpl let-control>
              <ng-container *ngIf="control.hasError('pattern')"
                >Invalid Phone number!</ng-container
              >
              <!-- <ng-container *ngIf="control.hasError('required')"
                >Please enter your Phone Number!</ng-container
              > -->
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired>Password</nz-form-label>
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            nzErrorTip="Please input your password!"
          >
            <nz-input-group [nzSuffix]="suffixIconSearch">
              <input
                class="controls"
                placeholder="password"
                nz-input
                type="{{ show ? 'text' : 'password' }}"
                formControlName="password"
                (ngModelChange)="validateConfirmPassword()"
              />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <i
                nz-icon
                *ngIf="show"
                (click)="showPass()"
                nzType="eye"
                nzTheme="outline"
              ></i>
              <i
                nz-icon
                *ngIf="!show"
                (click)="showPass()"
                nzType="eye-invisible"
                nzTheme="outline"
              ></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="7" nzRequired
            >Confirm Password</nz-form-label
          >
          <nz-form-control
            [nzSpan]="12"
            nzHasFeedback
            [nzErrorTip]="passwordErrorTpl"
          >
            <nz-input-group [nzSuffix]="suffixIconShow">
              <input
                class="controls"
                nz-input
                type="{{ confmPass ? 'text' : 'password' }}"
                formControlName="confirm"
                placeholder="confirm your password"
              />
            </nz-input-group>
            <ng-template #suffixIconShow>
              <i
                nz-icon
                *ngIf="confmPass"
                (click)="showCfmPass()"
                nzType="eye"
                nzTheme="outline"
              ></i>
              <i
                nz-icon
                *ngIf="!confmPass"
                (click)="showCfmPass()"
                nzType="eye-invisible"
                nzTheme="outline"
              ></i>
            </ng-template>
            <ng-template #passwordErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')"
                >Please confirm your password!</ng-container
              >
              <ng-container *ngIf="control.hasError('confirm')"
                >Password is inconsistent!</ng-container
              >
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button nz-button nzType="primary" [disabled]="!validateForm.valid">
              Submit
            </button>
            <button nz-button (click)="resetForm($event)">Reset</button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>
