import { Component, OnInit,OnDestroy } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { GraphqlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-pendings',
  templateUrl: './pendings.component.html',
  styleUrls: ['./pendings.component.css'],
})
export class PendingsComponent implements OnInit,OnDestroy {
  unApproved = new Array();
  private datasubscription!: Subscription ;
  constructor(private graphql: GraphqlService, private msg: NzMessageService) {}
  

  ngOnInit(): void {
    this.datasubscription = this.graphql.getUnapproved().subscribe((data) => {
      this.unApproved = data.data.task_manager_projects;
    });
  }
  approve(id: string) {
    this.graphql.approve(id).subscribe(
      (data) => {
        this.msg.success('Project Approved', { nzPauseOnHover: true });
      },
      (error) => {
        this.msg.error('Error while approving project', {
          nzPauseOnHover: true,
        });
      }
    );
  }
  reject(id: string) {
    this.graphql.reject(id).subscribe(
      (data) => {
        this.msg.success('Project Rejected', { nzPauseOnHover: true });
      },
      (error) => {
        this.msg.error('Error while rejecting project', {
          nzPauseOnHover: true,
        });
      }
    );
  }
  ngOnDestroy(): void {
  this.datasubscription.unsubscribe();
  }
}
