import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GraphqlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-newtask',
  templateUrl: './newtask.component.html',
  styleUrls: ['./newtask.component.css'],
})
export class NewtaskComponent implements OnInit {
  priorityList: any = [];
  statusList: any = [];
  validateForm!: FormGroup;
  id!: string;
  userId!: string;
  description!: string;
  dueDate!: string;
  user_id!: string;
  link!: string;
  priority!: string;
  projectId!: string;
  status!: string;
  title!: string;
  checkPriority!: string;
  showDuedate = false;
  today!: any;
  displayDate!: any;
  tomorrow!: any;
  hour!: any;
  modules: any;
  constructor(
    private fb: FormBuilder,
    private graphql: GraphqlService,
    private route: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
    private location: Location
  ) {}

  ngOnInit(): void {
    // calculate dispaly date based on current time
    this.today = new Date();
    this.hour = this.today.getHours();
    let datePipe = new DatePipe('en-IN');

    if (this.hour >= 11) {
      this.tomorrow = new Date();
      this.tomorrow.setDate(this.today.getDate() + 1);
      this.displayDate = datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
    } else {
      this.displayDate = datePipe.transform(this.today, 'yyyy-MM-dd');
    }

    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.userId = localStorage.getItem('user_id') || '';
    this.validateForm = this.fb.group({
      projectId: [this.id],
      userId: [this.userId],
      title: ['', [Validators.required]],
      link: [''],
      priority: ['', [Validators.required]],
      status: [''],
      description: ['', [Validators.required]],
      datePicker: [null],
    });
    this.graphql.fetchDropdown().subscribe((data) => {
      this.priorityList = data.data.task_manager_priority;
      this.statusList = data.data.task_manager_status;
    });
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline'], // toggled buttons
        // ['blockquote', 'code-block'],

        // [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ font: [] }],
        // [{ align: [] }],

        // ['clean'], // remove formatting button

        // ['link', 'image', 'video'], // link and image, video
        ['link'], // link and image, video
      ],
    };
  }
  back() {
    localStorage.setItem('back', 'true');
    this.router.navigateByUrl(`tasks/${this.id}`);
  }
  submitForm(): void {
    this.description = this.validateForm.value.description;
    this.dueDate = this.validateForm.value.datePicker;
    this.user_id = this.validateForm.value.userId;
    this.link = this.validateForm.value.link;
    this.priority = this.validateForm.value.priority;
    this.projectId = this.validateForm.value.projectId;
    this.status = this.validateForm.value.status;
    this.title = this.validateForm.value.title;
    this.checkPriority = this.validateForm.value.priority;
    if (this.checkPriority == 'Critical' || this.checkPriority == 'High') {
      if (this.dueDate == null || this.dueDate == '') {
        this.msg.error('Please choose Due date', { nzPauseOnHover: true });
        return;
      }
    }
    this.graphql
      .createTask(
        this.description,
        this.dueDate,
        this.user_id,
        this.link,
        this.priority,
        this.projectId,
        this.title,
        this.displayDate
      )
      .subscribe(
        ({ data }) => {
          this.msg.success('Task Added', { nzPauseOnHover: true });

          // loggin the task activiy
          let log = [
            {
              task_id: data.insert_task_manager_tasks.returning[0].id,
              user_id: this.user_id,
              activity_name: `Added Priority: ${this.priority}`,
            },
            {
              task_id: data.insert_task_manager_tasks.returning[0].id,
              user_id: this.user_id,
              activity_name: 'Added Status: NotYetStared',
            },
          ];

          if (this.link) {
            let temp = {
              task_id: data.insert_task_manager_tasks.returning[0].id,
              user_id: this.user_id,
              activity_name: 'Added Link',
            };
            log.push(temp);
          }

          if (this.dueDate) {
            let temp = {
              task_id: data.insert_task_manager_tasks.returning[0].id,
              user_id: this.user_id,
              activity_name: 'Added Due Date',
            };
            log.push(temp);
          }

          let temp = {
            task_id: data.insert_task_manager_tasks.returning[0].id,
            user_id: this.user_id,
            activity_name: 'Created Task',
          };

          log.push(temp);

          this.graphql.createLog(log).subscribe(({ data }) => {});
        },
        (error) => {
          this.msg.error('Error while adding task', { nzPauseOnHover: true });
          console.error(error);
        }
      );
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(key)) {
        this.validateForm.controls[key].markAsPristine();
        this.validateForm.controls[key].updateValueAndValidity();
      }
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls['confirm'].updateValueAndValidity()
    );
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls['password'].value) {
      return { confirm: true, error: true };
    }
    return {};
  };
  call() {
    this.checkPriority = this.validateForm.value.priority;
    if (this.checkPriority == 'Critical' || this.checkPriority == 'High') {
      this.showDuedate = true;
    } else {
      this.showDuedate = false;
    }
  }
}
