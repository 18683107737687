<div class="taskContainer">
  
  <i
  id="back"
  (click)="backArrow()"
  nz-icon
  nzType="arrow-left"
  nzTheme="outline"
></i>
<!-- Legends popup -->
  <nz-card class="popUp" style="width: 300px; height: 350px; overflow-y: scroll" *ngIf="isLegends" nzTitle="Legends"
    [nzExtra]="extraTemplate">
    <ul nz-list nzBordered nzSize="large">
      <nz-list-header>Proirity (Leftmost Colors)</nz-list-header>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legCritical" id="boxPrioriy"></div>
            </a>
          </nz-list-item-action>
        </ul>
        Critical
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legHigh" id="boxPrioriy"></div>
            </a>
          </nz-list-item-action>
        </ul>
        High
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legMedium" id="boxPrioriy"></div>
            </a>
          </nz-list-item-action>
        </ul>
        Medium
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legLow" id="boxPrioriy"></div>
            </a>
          </nz-list-item-action>
        </ul>
        Low
      </li>
    </ul>
    <ul nz-list nzBordered nzSize="large">
      <nz-list-header>Status Color Indications</nz-list-header>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legCompleted" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        Completed
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legClosed" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        Closed
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legReopened" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        Reopened
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legRejected" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        Rejected
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legNotYetStarted" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        NotYetStarted
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legInProgress" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        InProgress
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legOnHold" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        OnHold
      </li>
      <li nz-list-item nzNoFlex>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a>
              <div class="legNotDoable" id="box"></div>
            </a>
          </nz-list-item-action>
        </ul>
        NotDoable
      </li>
    </ul>
  </nz-card>
  <ng-template #extraTemplate>
    <a><i (click)="showLegends()" nz-icon nzType="close" nzTheme="outline"></i></a>
  </ng-template>
  <!-- /Legends popup -->

  <!-- filter form -->
  <form [formGroup]="filterForm" *ngIf="showFilter" style="width: 90%" nz-form class="ant-advanced-search-form my-3">
    <!-- title -->
    <nz-form-item class="mx-2">
      <nz-form-label>Title</nz-form-label>
      <nz-form-control>
        <input class="title-input" nz-input placeholder="title" formControlName="title" />
      </nz-form-control>
    </nz-form-item>

    <!-- Priority -->
    <nz-form-item class="mx-2">
      <nz-form-label>Priority</nz-form-label>
      <nz-form-control [nzSpan]="12" nzErrorTip="Please Choose priority">
        <nz-select class="priority-select" nzPlaceHolder="Choose" formControlName="priority">
          <nz-option *ngFor="let data of priorityList" [nzValue]="data.priority" [nzLabel]="data.priority"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Status -->
    <nz-form-item class="mx-2">
      <nz-form-label>Status</nz-form-label>
      <nz-form-control [nzSpan]="12" nzErrorTip="Please Choose status">
        <nz-select class="status-select" nzPlaceHolder="Choose" formControlName="status">
          <nz-option *ngFor="let data of statusList" [nzValue]="data.status" [nzLabel]="data.status"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Assignee -->
    <nz-form-item class="mx-2">
      <nz-form-label>Assignee</nz-form-label>
      <nz-form-control [nzSpan]="12" nzErrorTip="Please Choose assignee">
        <nz-select class="status-select" nzPlaceHolder="Choose" formControlName="assignee">
          <nz-option *ngFor="let data of employeeList" [nzValue]="data.external_id" [nzLabel]="data.name"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <button nz-button (click)="search()" [nzType]="'primary'">Search</button>
    <button nz-button (click)="resetForm()">Clear</button><br />

    <div class="filter">
      <nz-form-item class="mx-2">
        <nz-form-label>Filter Name</nz-form-label>
        <nz-form-control>
          <input nz-input placeholder="Filter Name" formControlName="filterName" />
        </nz-form-control>
      </nz-form-item>
      <button nz-button (click)="saveFilter()">Save Filter</button>
    </div>

    <div nz-row></div>
  </form>

  <h3 [ngStyle]="{ margin: '16px 0' }">{{ title }}</h3>
  <span *ngIf="!showFilter" nz-tooltip nzTooltipTitle="Show Filter">
    <button (click)="toggleFilter()" nz-button nzType="default" class="my-2" nzShape="circle">
      <i nz-icon nzType="search"></i>
    </button>
  </span>

  <span *ngIf="showFilter" nz-tooltip nzTooltipTitle="Close Filter">
    <button (click)="toggleFilter()" nz-button nzType="default" class="my-2" nzShape="circle">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
  </span>

  <!-- saved filters -->

  <div class="saved-filters">
    <ng-container>
      <button *ngIf="userRole == 'BEETLER'" nz-button (click)="myTasks()">
        My Tasks
      </button>
      <button (click)="export()" nz-button>Export</button>
      <button style="float: right; background-color: #d01487" (click)="showLegends()" nz-button nzType="default"
        nzDanger>
        Legends
      </button>
      <button *ngIf="savedFilters.length > 0" nz-button nz-dropdown [nzDropdownMenu]="menu"
        [nzPlacement]="dropPosition[0]">
        Saved Filters
      </button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu *ngFor="let filter of savedFilters; index as i">
          <li (click)="applyFilter(i)" nz-menu-item>{{ filter.name }}</li>
        </ul>
      </nz-dropdown-menu>
    </ng-container>
  </div>

  <!-- saved filters -->
  <h3 style="color: royalblue;">{{projectName}}</h3>
  <nz-empty *ngIf="noData"></nz-empty>

  <!-- task data -->
  <!-- <div *ngIf="isLoading && !noData" class="loader">
    <nz-spin nzSimple></nz-spin>
  </div> -->
 
  <div *ngIf="!isLoading && !noData" class="dataContainer">
    <div *ngFor="let data of taskData">
      <h4 *ngIf="data && data.tasks.length > 0">
        <i>{{ data.date }}</i>
      </h4>
      <div *ngIf="data && data.tasks.length > 0">
        <ul *ngFor="let task of data.tasks" nz-list nzBordered nzSize="large">
          <li routerLink="{{ task.id }}" class=" {{ task.priority }} " nz-list-item nzNoFlex>
            <ul nz-list-item-actions>
              <nz-list-item-action *ngIf="task.estimated_date ">
                Estimated date : {{ task.estimated_date | date }}
              </nz-list-item-action>
              <nz-list-item-action *ngIf="task.assigned_to">
                Assignee : {{ task.assignee.name }}
                <!-- <nz-avatar nzIcon="user"></nz-avatar> -->
              </nz-list-item-action>
              <nz-list-item-action>
                <span style="color: black" class="d-flex">Status&nbsp; &nbsp;
                  <div class="{{ task.status }}" id="box"></div>
                </span>
              </nz-list-item-action>
            </ul>
            {{ task.title }}
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>

  <button *ngIf="userRole != 'BEETLER'" routerLink="newTask" nz-button nzType="primary" [nzSize]="size" nzShape="round">
    <i nz-icon nzType="plus" nzTheme="outline"></i>
    Add Task
  </button>
  <!-- <nz-pagination
    (nzPageIndexChange)="pageIndexChanged($event)"
    [nzPageSize]="queryParams.limit"
    class="my-2"
    style="float: right"
    [nzPageIndex]="1"
    [nzTotal]="totaltasks"
  ></nz-pagination> -->
</div>