import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { AddEmployeeComponent } from './pages/add-employee/add-employee.component';

import { NavbarComponent } from './pages/navbar/navbar.component';
import { AddClientComponent } from './pages/add-client/add-client.component';
import { SidenavComponent } from './pages/sidenav/sidenav.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { SingleTaskComponent } from './pages/single-task/single-task.component';
import { NewtaskComponent } from './pages/newtask/newtask.component';
import { AddprojectComponent } from './pages/addproject/addproject.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { environment } from 'src/environments/environment';

// apollo
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { GraphQLModule } from './graphql.module';

//ant-design imports
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { PendingsComponent } from './pages/pendings/pendings.component';
import { RejectedComponent } from './pages/rejected/rejected.component';
import { ContentComponent } from './pages/content/content.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MentionModule } from 'angular-mentions';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { QuillModule } from 'ngx-quill';
import { PendingActionsComponent } from './pages/pending-actions/pending-actions.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddEmployeeComponent,
    NavbarComponent,
    AddClientComponent,
    SidenavComponent,
    TasksComponent,
    SingleTaskComponent,
    CommonLayoutComponent,
    NewtaskComponent,
    AddprojectComponent,
    UserProfileComponent,
    EditProfileComponent,
    PendingsComponent,
    RejectedComponent,
    ContentComponent,
    DashboardComponent,
    PendingActionsComponent,
  ],

  imports: [
    ApolloModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzCardModule,
    NzGridModule,
    NzBadgeModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzCheckboxModule,
    NzMenuModule,
    NzCollapseModule,
    NzStepsModule,
    NzIconModule,
    NzListModule,
    NzToolTipModule,
    NzLayoutModule,
    NzDropDownModule,
    NzAvatarModule,
    NzDescriptionsModule,
    NzSelectModule,
    NzCommentModule,
    NzBreadCrumbModule,
    NzDrawerModule,
    NzDatePickerModule,
    NzEmptyModule,
    NzPaginationModule,
    NzMessageModule,
    NzDividerModule,
    NzSpinModule,
    GraphQLModule,
    NzSwitchModule,
    MentionModule,
    QuillModule.forRoot(),
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphql,
          }),
        };
      },
      deps: [HttpLink],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
