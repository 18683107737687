<div *ngIf="taskData" class="container1">
  <!-- activity log  button-->
  <div style="float: right">
    <button nz-button nzType="primary" (click)="back()">Back</button>&nbsp;
    <button nz-button nzType="primary" (click)="open()">Activity Log</button
    >&nbsp;
    <button
      class="update-btn"
      nz-button
      nzType="primary"
      nzGhost
      (click)="update()"
    >
      Update
    </button>

    <!-- activity log -->
    <nz-drawer
      [nzClosable]="false"
      [nzVisible]="visible"
      nzPlacement="right"
      nzTitle="Activity Log"
      (nzOnClose)="close()"
    >
      <ng-container *nzDrawerContent>
        <nz-steps
          [nzCurrent]="activityLog.length"
          nzProgressDot
          nzDirection="vertical"
        >
          <nz-step
            *ngFor="let log of activityLog"
            nzTitle="{{ log.activity_name }}"
            nzDescription="{{ log.created_at | date: 'medium' }}"
          ></nz-step>
        </nz-steps>
      </ng-container>
    </nz-drawer>
  </div>
  <!-- activity log -->
  <h2 class="headings"><u>{{project}}</u></h2>
  <h3 class="headings">{{ taskData.title }}</h3>
  <h6 class="date">Created : {{ taskData.created_at | date }}</h6>
  <h6 class="date" *ngIf="taskData.due_date">
    Due : {{ taskData.due_date | date }}
  </h6>
  <h6 class="date" *ngIf="taskData.estimated_date">
    Estimated : {{ taskData.estimated_date | date }}
  </h6>
  <h6 class="date" *ngIf="taskData.billable_time">
    Time required : {{ time_est }}
  </h6>

  <br />
  <!-- first row -->
  <div style="display: flex; margin-bottom: 20px">
    <div class="dropdown">
      <label>Priority :&nbsp;</label>
      <nz-select
        [disabled]="
          (!isStudyMedic && !isAdmin && !isAjith) ||
          taskData.action == 'Closed' ||
          taskData.action == 'Rejected'
        "
        class="priority-select"
        nzAllowClear
        [nzPlaceHolder]="taskData.priority ? taskData.priority : 'Choose'"
        [(ngModel)]="selectedPriority"
      >
        <nz-option
          *ngFor="let data of priorityList"
          [nzLabel]="data.priority"
          [nzValue]="data.priority"
        ></nz-option>
      </nz-select>
    </div>
    <div class="dropdown">
      <label>Status :&nbsp;</label>
      <nz-select
        (nzOpenChange)="showAction()"
        class="select"
        [disabled]="
          (!isBeetler && !isAdmin) ||
          taskData.action == 'Closed' ||
          taskData.action == 'Rejected'
        "
        nzAllowClear
        [nzPlaceHolder]="taskData.status ? taskData.status : 'Choose'"
        [(ngModel)]="selectedStatus"
      >
        <nz-option nzValue="Completed" nzLabel="Completed"></nz-option>
        <nz-option
          nzValue="NotYetStarted"
          nzLabel="Not Yet Started"
        ></nz-option>
        <nz-option nzValue="InProgress" nzLabel="In Progress"></nz-option>
        <nz-option nzValue="OnHold" nzLabel="On Hold"></nz-option>
        <nz-option nzValue="NotDoable" nzLabel="Not Doable"></nz-option>
        <nz-option nzValue="DateExceeded" nzLabel="Date Exceeded"></nz-option>
        <nz-option
          nzValue="IssueNotFound"
          nzLabel="Issue Not Found"
        ></nz-option>
      </nz-select>
    </div>
  </div>
  <!-- second row -->
  <div style="display: flex; margin-bottom: 20px">
    <div *ngIf="showActions" class="dropdown">
      <label>Actions :&nbsp;</label>
      <nz-select
        [disabled]="!isAdmin && !isAjith"
        class="priority-select"
        nzAllowClear
        [nzPlaceHolder]="taskData.action ? taskData.action : 'Choose'"
        [(ngModel)]="selectedAction"
      >
        <nz-option
          *ngFor="let data of actionsList"
          [nzLabel]="data.action"
          [nzValue]="data.action"
        ></nz-option>
      </nz-select>
    </div>
    <div class="dropdown">
      <label for="cars">Assigned To :&nbsp;</label>
      <nz-select
        [disabled]="
          (!isAdmin && !isAjith) ||
          taskData.action == 'Closed' ||
          taskData.action == 'Rejected'
        "
        class="select"
        nzAllowClear
        [nzPlaceHolder]="
          taskData.assigned_to ? assigned_to : 'Select an Employee'
        "
        [(ngModel)]="selectedValue"
      >
        <nz-option
          *ngFor="let item of employeeList"
          [nzLabel]="item.name"
          [nzValue]="item"
        ></nz-option>
      </nz-select>
    </div>
  </div>
  <!-- Third row -->
  <div style="display: flex; margin-bottom: 20px">
    <div class="dropdown">
      <label for="isBillable">Is Billable :&nbsp;</label>
      <nz-select
        [disabled]="
          !isAdmin ||
          taskData.action == 'Closed' ||
          taskData.action == 'Rejected'
        "
        class="select"
        [nzPlaceHolder]="taskData.is_billable ? 'Yes' : 'No'"
        [(ngModel)]="isBillable"
      >
        <nz-option nzLabel="Yes" nzValue="true"></nz-option>
        <nz-option nzLabel="No" nzValue="false"></nz-option>
      </nz-select>
    </div>
    <div *ngIf="taskData.assigned_to == userData.external_id" class="dropdown">
      <label>Time Spend </label>
      <input
        [disabled]="
          taskData.action == 'Closed' || taskData.action == 'Rejected'
        "
        [(ngModel)]="billable_time"
        class="mx-3"
        type="text"
        placeholder="Time in minutes"
      />
    </div>
  </div>
  <!-- fourth row -->
  <div style="display: flex; margin-bottom: 20px">
    <div
      *ngIf="
        isAdmin ||
        isAjith ||
        (isStudyMedic &&
          (selectedPriority == 'Critical' ||
            selectedPriority == 'High' ||
            taskData.priority == 'Critical' ||
            taskData.priority == 'High'))
      "
      class="dropdown"
    >
      <label for="cars">Due Date :&nbsp;</label>
      <nz-date-picker
        [disabled]="
          (!isStudyMedic && !isAdmin && !isAjith) ||
          taskData.action == 'Closed' ||
          taskData.action == 'Rejected'
        "
        class="select"
        [(ngModel)]="selectedDueDate"
      ></nz-date-picker>
    </div>

    <div
      *ngIf="
        !isStudyMedic &&
        (taskData.priority == 'Critical' || taskData.priority == 'High')
      "
      class="dropdown"
    >
      <label for="cars">Estimated Date :&nbsp;</label>
      <nz-date-picker
        [disabled]="
          (!isAdmin && !isBeetler) ||
          taskData.action == 'Closed' ||
          taskData.action == 'Rejected'
        "
        [(ngModel)]="selectedEstDate"
        class="select"
      ></nz-date-picker>
    </div>
  </div>
  <div
    style="border: 2px solid rgb(4, 4, 79); padding: 20px; border-radius: 7px"
  >
    <nz-descriptions nzTitle="Description"> </nz-descriptions>
    <quill-view theme="snow" [content]="taskData.description"></quill-view>
  </div>
  <br />
  <div style="display: flex">
    <label for="Link" style="font-size: 20px">Link </label>

    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input
        [disabled]="
          !isStudyMedic ||
          taskData.action == 'Closed' ||
          taskData.action == 'Rejected'
        "
        nz-input
        placeholder="{{ link }}"
        [(ngModel)]="selectedLink"
      />
    </nz-input-group>

    <ng-template #suffixIconSearch>
      <a [href]="link" target="_blank"
        ><button
          [disabled]="link == ''"
          nz-button
          nzType="primary"
          style="height: 30px"
        >
          Go
        </button></a
      >
    </ng-template>
  </div>
  <br />

  <!-- /activity log -->
  <!-- comments from Athul & S&H section -->
  <div>
    <hr />
    <div *ngIf="athulComment.length">
      <h5 class="headings">Comment from Athul:</h5>
      <div *ngFor="let data of athulComment">
        <nz-comment nzAuthor="Athul">
          <nz-avatar nz-comment-avatar nzIcon="user"></nz-avatar>
          <nz-comment-content>
            <p>
              {{ data.comment }}
            </p>
            <p *ngIf="data.url">
              <a target="_blank" [href]="data.url">{{ data.url }}</a>
            </p>
            <label class="date">{{ data.created_at | date: "medium" }}</label>
          </nz-comment-content>
        </nz-comment>
      </div>
      <hr />
    </div>

    <div *ngIf="beetleComment.length">
      <h5 class="headings">Response From S&H:</h5>
      <div *ngFor="let data of beetleComment">
        <nz-comment [nzAuthor]="data.user.name">
          <nz-avatar nz-comment-avatar nzIcon="user"></nz-avatar>
          <nz-comment-content>
            <p>
              {{ data.comment }}
            </p>
            <p *ngIf="data.url">
              <a target="_blank" [href]="data.url">{{ data.url }}</a>
            </p>
            <label class="date">{{ data.created_at | date: "medium" }}</label>
          </nz-comment-content>
        </nz-comment>
      </div>
      <hr />
    </div>
  </div>
  <!-- /comments from Athul & S&H section -->
  <!-- users comments -->
  <h6 class="headings">Comments:</h6>
  <div *ngFor="let data of commentList">
    <nz-comment [nzAuthor]="data.user.name">
      <nz-avatar nz-comment-avatar nzIcon="user"></nz-avatar>
      <nz-comment-content>
        <label style="color: blue" *ngFor="let tag of data.tags"
          >@{{ tag.user.name }}&nbsp;</label
        >
        <p>
          {{ data.comment }}
        </p>
        <p *ngIf="data.url">
          <a target="_blank" [href]="data.url">{{ data.url }}</a>
        </p>
        <label class="date">{{ data.created_at | date: "medium" }}</label>
      </nz-comment-content>
    </nz-comment>
  </div>

  <div>
    <nz-list
      *ngIf="data.length"
      [nzDataSource]="data"
      [nzRenderItem]="item"
      [nzItemLayout]="'horizontal'"
    >
      <ng-template #item let-item>
        <nz-comment [nzAuthor]="item.author" [nzDatetime]="item.displayTime">
          <nz-avatar nz-comment-avatar nzIcon="user"></nz-avatar>
          <nz-comment-content>
            <p>{{ item.content }}</p>
          </nz-comment-content>
        </nz-comment>
      </ng-template>
    </nz-list>
    <nz-comment>
      <nz-avatar nz-comment-avatar nzIcon="user"></nz-avatar>
      <nz-comment-content>
        <nz-form-item>
          <textarea
            [mentionConfig]="{ labelKey: 'text' }"
            [mention]="listOfOption"
            [(ngModel)]="inputValue"
            nz-input
            rows="4"
          ></textarea>
        </nz-form-item>

        <div style="display: flex">
          <!-- tagging -->
          <!-- <div>
            <nz-select
              nzShowSearch
              nzServerSearch
              nzPlaceHolder="Search user"
              [(ngModel)]="tag"
              [nzShowArrow]="true"
              [nzFilterOption]="nzFilterOption"
              (nzOnSearch)="search($event)"
            >
              <nz-option
                *ngFor="let o of listOfOption"
                [nzLabel]="o.text"
                [nzValue]="o"
              >
              </nz-option> </nz-select
            >&nbsp;
            <button
              nz-button
              (click)="tagUser()"
              [nzLoading]="isBatchButtonLoading"
              nzType="primary"
            >
              Tag
            </button>
            &nbsp;
            <button
              [disabled]="tag == ''"
              nz-button
              (click)="reset()"
              nzType="primary"
            >
              Clear
            </button>
          </div> -->
          <!-- tagging -->
          <!-- insert link in comments -->
          <span *ngIf="!isLink" nz-tooltip nzTooltipTitle="Insert Link"
            ><i
              (click)="insertLink()"
              style="font-size: 20px; cursor: pointer"
              nz-icon
              nzType="link"
              nzTheme="outline"
            ></i
          ></span>
          <nz-input-group
            style="width: 200px"
            *ngIf="isLink"
            [nzSuffix]="suffixIconLink"
          >
            <input nz-input placeholder="Insert url" [(ngModel)]="linkValue" />
          </nz-input-group>
          <ng-template #suffixIconLink
            ><i
              (click)="closeLink()"
              id="insertLink"
              nz-icon
              nzType="close-square"
              nzTheme="outline"
            ></i
          ></ng-template>
          <!-- /insert link in comments -->
        </div>

        <nz-form-item>
          <button
            class="my-2"
            nz-button
            nzType="primary"
            [nzLoading]="submitting"
            [disabled]="!inputValue"
            (click)="handleSubmit()"
          >
            Add Comment
          </button>
        </nz-form-item>
      </nz-comment-content>
    </nz-comment>
  </div>
</div>
