<div class="container1">
  <nz-list class="list" nzGrid>
    <!-- search -->
    <div class="my-2">
      <nz-select
      style="min-width:84% ;"
        nzShowSearch
        nzServerSearch
        nzPlaceHolder="Search Project"
        [(ngModel)]="selectedValue"
        [nzShowArrow]="true"
        [nzFilterOption]="nzFilterOption"
        (nzOnSearch)="search($event)"
      >
        <nz-option
          *ngFor="let o of listOfOption"
          [nzLabel]="o.text"
          [nzValue]="o.value"
        >
        </nz-option>
      </nz-select>
      <button
        nz-button
        (click)="searchQuiz()"
        [nzLoading]="isBatchButtonLoading"
        nzType="primary"
      >
        Search
      </button>
      &nbsp;
      <button
        [disabled]="selectedValue == ''"
        nz-button
        (click)="reset()"
        nzType="primary"
      >
        Clear
      </button>
    </div>

    <!--/ search -->
    <div class="list-container" nz-row [nzGutter]="[16, 8]">
      <div
        nzLg="12"
        nzMd="12"
        nzSm="12"
        nzXs="12"
        nz-col
        [nzSpan]="4"
        *ngFor="let item of projectData"
      >
        <nz-list-item
          [routerLink]="isClicked || isOpen ? './' : ['../', 'tasks', item.id]"
          [state]="{ title: item.title }"
        >
          <nz-card class="list-item">
            <h6>{{ item.title }}</h6>
            <div class="status">
              <p>In Progress</p>
              <p>{{ item.InProgress }}</p>
            </div>
            <div class="status">
              <p>Completed</p>
              <p>{{ item.Completed }}</p>
            </div>
            <div class="status">
              <p>Rejected</p>
              <p>{{ item.Rejected }}</p>
            </div>
            
            <div *ngIf="item.production_release" class="status">
              <p>Next production Release</p>
              <p>{{ item.production_release | date }}</p>
            </div>
            <button
              *ngIf="userRole != 'STUDYMEDICIAN'"
              nz-button
              (click)="addDate(item.id, item.title)"
              nzType="primary"
            >
              Update Production Release
            </button>
            &nbsp;
            <button
              *ngIf=" isAdmin || isAthul"
              nz-button
              (click)="showMembers(item.id)"
              nzType="primary"
            >
              Add Members
            </button>
          </nz-card>
        </nz-list-item>
      </div>
      <!-- users popup -->
      <div *ngIf="isOpen" class="pop-container">
        <div style="background-color: white">
          <ul
          class="user-list py-3"
            nz-list
            nzBordered
            nzSize="large"
          >
            <h3 style="text-align: center" [ngStyle]="{ margin: '16px 0' }">
              Users
            </h3>
            <li nz-list-item *ngFor="let item of listOfUsers" nzNoFlex>
              <label nz-checkbox nzValue="item.value" (ngModelChange)=checked(item,$event)  [(ngModel)]="item.checked">{{item.label}}</label>
            </li>
            <div style="width: 100%; text-align: center">
              <button nz-button (click)="addMembers()" nzType="primary">
                Update</button
              >&nbsp;
              <button nz-button (click)="close()" nzType="primary">
                Close
              </button>
            </div>
          </ul>
        </div>
      </div>

      <!--/ users popup -->
      <div id="pop-box" *ngIf="isClicked">
        <nz-card *ngIf="isClicked" id="popup" class="list-item">
          <h6>{{ heading }}</h6>
          <nz-date-picker
            style="width: 100%"
            [(ngModel)]="date"
          ></nz-date-picker
          ><br /><br />
          <button nz-button (click)="update()" nzType="primary">Update</button
          >&nbsp;
          <button nz-button (click)="cancel()" nzType="primary">Cancel</button>
        </nz-card>
      </div>
    </div>

    <nz-pagination
      (nzPageIndexChange)="pageIndexChanged($event)"
      [nzPageSize]="queryParams.limit"
      [nzPageIndex]="1"
      style="float: right"
      [nzTotal]="totalCount"
    ></nz-pagination>
  </nz-list>
</div>
