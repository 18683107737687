import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css'],
})
export class AddEmployeeComponent implements OnInit {
  validateForm: FormGroup;
  show!: Boolean;
  confmPass!: Boolean;
  submitForm(): void {
    this.auth.register(
      this.validateForm.value.name,
      this.validateForm.value.email,
      this.validateForm.value.phone_number,
      this.validateForm.value.password,
      'BEETLER'
    );
  }
  //back button
  back() {
    this.location.back();
  }
  //show/hide password
  showPass() {
    this.show = !this.show;
  }
  showCfmPass() {
    this.confmPass = !this.confmPass;
  }
  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(key)) {
        this.validateForm.controls[key].markAsPristine();
        this.validateForm.controls[key].updateValueAndValidity();
      }
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls['confirm'].updateValueAndValidity()
    );
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls['password'].value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private location: Location
  ) {
    this.validateForm = this.fb.group({
      name: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'),
        ],
      ],
      phone_number: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      password: ['', [Validators.required]],
      confirm: ['', [this.confirmValidator]],
      // comment: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {}
}
