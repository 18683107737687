<div class="container-box">
  <i
  id="back"
  (click)="backArrow()"
  nz-icon
  nzType="arrow-left"
  nzTheme="outline"
></i>
  <h3 [ngStyle]="{ margin: '16px 0', color: 'royalblue' }">{{ title }}</h3>
  <ul
    nz-list
    [nzDataSource]="taskData"
    *ngFor="let item of taskData"
    nzBordered
    nzSize="large"
  >
    <li routerLink="{{ item.id }}" class="my-2" nz-list-item nzNoFlex>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <i
            *ngIf="action == 'Closed'"
            nz-icon
            style="color: red"
            nzType="close-circle"
            nzTheme="outline"
          ></i>
          <i
            *ngIf="action == 'Rejected'"
            nz-icon
            style="color: red"
            nzType="stop"
            nzTheme="outline"
          ></i>
          <i
            *ngIf="action == 'Reopened'"
            nz-icon
            nzType="check-circle"
            style="color: green"
            nzTheme="outline"
          ></i>
          <i
            *ngIf="action == 'Website_Launch'"
            nz-icon
            nzType="chrome"
            style="color: green"
            nzTheme="outline"
          ></i>
          <i
            *ngIf="action == 'Phase_2'"
            nz-icon
            nzType="block"
            nzTheme="outline"
          ></i>
        </nz-list-item-action>
      </ul>
      {{ item.title }}
    </li>
    <li></li>
  </ul>
  <div class="empty">
    <nz-empty *ngIf="taskData.length == 0"></nz-empty>
  </div>
</div>
