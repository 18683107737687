import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from 'src/app/interfaces/user';
import { AuthService } from 'src/app/services/auth.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
})
export class EditProfileComponent implements OnInit {
  editForm!: FormGroup;
  show!: Boolean;
  showOld!: Boolean;
  confmPass!: Boolean;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private message: NzMessageService,
    private location: Location
  ) {}
  user!: User;
  errorMsg!: string;
  isError: boolean = false;

  ngOnInit(): void {
    // USER ID
    this.user = <User>JSON.parse(localStorage.getItem('user')!);
    this.editForm = this.fb.group({
      name: [this.user.name],
      email: [
        this.user.email,
        [Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')],
      ],
      phone_number: [
        this.user.phone_number,
        [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      oldPassword: [''],
      newPassword: [''],
      confirmNewPassword: ['', [this.confirmValidator]],
    });
  }
  //back button
  back() {
    this.location.back();
  }
  //show/hide password
  showPass() {
    this.show = !this.show;
  }
  showOldPass() {
    this.showOld = !this.showOld;
  }
  showCfmPass() {
    this.confmPass = !this.confmPass;
  }
  // validate confirm new password
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.editForm.controls['newPassword'].value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.editForm.controls['confirmNewPassword'].updateValueAndValidity()
    );
  }

  submitForm(): void {
    this.isError = false;
    this.errorMsg = '';
    let queryParams = {
      where: {
        external_id: {
          _eq: this.user.external_id,
        },
      },
      _set: {
        name: String,
        email: String,
        phone_number: String,
        password: String,
      },
      oldPassword: String,
    };

    if (this.editForm.controls['name'].dirty) {
      queryParams._set.name = this.editForm.controls['name'].value;
    }
    if (this.editForm.controls['email'].dirty) {
      queryParams._set.email = this.editForm.controls['email'].value;
    }
    if (this.editForm.controls['phone_number'].dirty) {
      queryParams._set.phone_number =
        this.editForm.controls['phone_number'].value;
    }
    if (this.editForm.controls['newPassword'].dirty) {
      queryParams._set.password = this.editForm.controls['newPassword'].value;
      queryParams.oldPassword = this.editForm.controls['oldPassword'].value;
    }

    if (this.editForm.pristine) {
      this.isError = true;
      this.errorMsg = 'Please make changes before submit';
      return;
    }

    this.auth.editProfile(queryParams).subscribe(
      (data) => {
        this.isError = false;
        this.message.success('Profile updated');
      },
      ({ error }) => {
        this.isError = true;
        this.errorMsg = error.message;
      }
    );
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.editForm.reset();
    for (const key in this.editForm.controls) {
      if (this.editForm.controls.hasOwnProperty(key)) {
        this.editForm.controls[key].markAsPristine();
        this.editForm.controls[key].updateValueAndValidity();
      }
    }
  }
}
