import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatDistance } from 'date-fns';
import { NzMessageService } from 'ng-zorro-antd/message';
import { User } from 'src/app/interfaces/user';
import { GraphqlService } from 'src/app/services/graphql.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-single-task',
  templateUrl: './single-task.component.html',
  styleUrls: ['./single-task.component.css'],
})
export class SingleTaskComponent implements OnInit, OnDestroy {
  selectedValue!: User;
  isBillable = false;
  selectedPriority = null;
  selectedAction = null;
  showActions = false;
  selectedStatus = null;
  selectedDueDate = null;
  selectedEstDate = null;
  selectedLink = null;
  isClicked = false;
  is_studymedic = false;
  is_sh = false;
  link = '';
  linkValue = '';
  isLink = false;
  id!: string;
  taskData: any;
  priorityList: any = [];
  statusList: any = [];
  actionsList: any = [];
  employeeList: any = [];
  commentList: any = [];
  userRole!: string;
  userData!: User;
  edit: boolean = false;
  athulComment: any = [];
  beetleComment: any = [];
  billable_time = null;
  time_est!: string;
  private taskSubscription!: Subscription;
  private activitylogSubscription!: Subscription;
  time = formatDistance(new Date(), new Date());
  data: any[] = [];
  submitting = false;
  inputValue = '';
  tag: any;
  assigned_to!: string;
  isAdmin: boolean = false;
  isStudyMedic: boolean = false;
  isBeetler: boolean = false;
  activityLog = new Array();
  isAjith = false;
  url = '';
  project!: string;
  body: any;
  isBatchButtonLoading!: boolean;
  listOfOption: Array<{ value: string; text: string }> = [];
  nzFilterOption = () => true;
  constructor(
    private route: ActivatedRoute,
    private graphql: GraphqlService,
    private message: NzMessageService,
    private auth: AuthService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
    // check user role
    this.userData = <User>JSON.parse(localStorage.getItem('user')!);
    this.userRole = this.userData.role;
    if (this.userData.external_id == environment.AJITH_ID) {
      this.isAjith = true;
    } else {
      this.isAjith = false;
    }
    if (this.userRole == 'ADMIN') this.isAdmin = true;
    if (this.userRole == 'BEETLER' || this.userRole === 'PROJECT_MANAGER')
      this.isBeetler = true;
    if (this.userRole == 'STUDYMEDICIAN') this.isStudyMedic = true;

    this.id = this.route.snapshot.paramMap.get('tid') || '';
    this.taskSubscription = this.graphql.getTask(this.id).subscribe((data) => {
      this.taskData = data.data.task_manager_tasks[0];

      this.project = this.taskData.project.title;

      if (
        this.taskData.status == 'Completed' ||
        this.taskData.status == 'IssueNotFound' ||
        this.taskData.status == 'NotDoable'
      ) {
        this.showActions = true;
      }

      if (this.taskData.billable_time) {
        this.calculateTime(this.taskData.billable_time);
      }
      this.link = this.taskData.link;
      if (this.taskData.assigned_to) {
        this.graphql.getUser(this.taskData.assigned_to).subscribe((user) => {
          this.assigned_to = user.data.task_manager_users[0].name;
        });
      }
    });

    this.graphql.fetchDropdown().subscribe((data) => {
      this.priorityList = data.data.task_manager_priority;
      this.statusList = data.data.task_manager_status;
      this.actionsList = data.data.task_manager_actions;
    });
    this.graphql.getEmployee().subscribe((data) => {
      this.employeeList = data.data.task_manager_users;
    });
    this.graphql.getComments(this.id).subscribe((data) => {
      this.commentList = data.data.task_manager_comments;
    });

    this.graphql.getAthulComment(this.id).subscribe(({ data }) => {
      this.athulComment = data.task_manager_comments;
    });

    this.graphql.getSHComment(this.id).subscribe(({ data }) => {
      this.beetleComment = data.task_manager_comments;
    });

    // get activiy log
    this.activitylogSubscription = this.graphql
      .getLogs(this.id)
      .subscribe(({ data }) => {
        this.activityLog = data.task_manager_activity_log;
      });
  }
  fetchUsers() {
    this.graphql.fetchUsers().subscribe(({ data, loading }) => {
      const listOfOption: Array<{ value: string; text: string }> = [];
      data.task_manager_users.forEach((item: any) => {
        listOfOption.push({
          value: item.external_id,
          text: item.name,
        });
      });
      this.listOfOption = listOfOption;
    });
  }
  showAction() {
    if (
      this.selectedStatus == 'Completed' ||
      this.selectedStatus == 'IssueNotFound' ||
      this.selectedStatus == 'NotDoable'
    ) {
      this.showActions = true;
    }
  }
  insertLink() {
    this.isLink = true;
  }
  closeLink() {
    this.isLink = false;
    this.linkValue = '';
  }
  //activity log section

  visible = false;

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
  back() {
    this.location.back();
  }

  handleSubmit(): void {
    this.submitting = true;
    const content = this.inputValue;
    const url = this.linkValue;
    if (this.userData.external_id == environment.ATHUL_ID) {
      this.is_studymedic = true;
    }
    if (this.isAdmin) {
      this.is_sh = true;
    }
    this.auth
      .sendComment({
        projectId: this.taskData.project_id,
        body: {
          user: this.userData.name,
          project: this.project,
          task: this.taskData.title,
          comment: content,
          projectId: this.taskData.project_id,
          taskId: this.id,
        },
      })
      .subscribe(
        (data) => {
          console.log(data);
        },
        (error) => {
          console.log(error);
        }
      );
    this.graphql
      .addComment(
        content,
        url,
        this.is_sh,
        this.is_studymedic,
        this.id,
        this.userData.external_id
      )
      .subscribe((data) => {
        console.log(data);

        // this.commentId=data.data.insert_task_manager_comments.returning[0].id;
        // let tags= this.tagedUserId.map((tag:any)=>{
        //   return {
        //     user_id:tag,
        //     comment_id:this.commentId
        //   }
        //  })
        //  this.graphql.addTags(tags,this.id).subscribe((data)=>{
        //   console.log(data);

        //  })
        this.submitting = false;
      });
    this.inputValue = '';
  }
  //calculateTime
  calculateTime(time: any) {
    if (time % 60 == 0) {
      this.time_est = `${Math.floor(time / 60)} : 00 hrs`;
    } else {
      if (time % 60 < 10)
        this.time_est = `${Math.floor(time / 60)} : 0${time % 60} hrs`;
      else this.time_est = `${Math.floor(time / 60)} : ${time % 60} hrs`;
    }
  }

  // for updating data to db
  update() {
    // create activiy log

    let log = new Array();

    let addLog = (activity_name: string) => {
      let temp = {
        task_id: this.id,
        user_id: this.userData.external_id,
        activity_name: activity_name,
      };
      log.push(temp);
    };

    let queryParams = {
      where: {
        id: {
          _eq: this.id,
        },
      },
      _set: {
        assigned_to: String,
        link: String,
        priority: String,
        status: String,
        action: String,
        due_date: String,
        estimated_date: String,
        billable_time: 0,
        is_billable: false,
        is_closed: false,
      },
    };
    if (
      this.selectedPriority ||
      this.selectedStatus ||
      this.selectedValue ||
      this.selectedDueDate ||
      this.selectedLink ||
      this.selectedEstDate ||
      this.billable_time ||
      this.selectedAction ||
      (this.isBillable == true ? !this.isBillable : this.isBillable)
    ) {
      if (
        (this.selectedStatus == 'Completed' ||
          this.selectedStatus == 'IssueNotFound' ||
          this.selectedStatus == 'NotDoable') &&
        this.isAdmin
      ) {
        if (this.selectedAction == null || this.selectedAction == '') {
          this.message.error('Choose an action', { nzPauseOnHover: true });
          return;
        }
      }
      // insert priority
      if (this.selectedPriority) {
        // insert activiy

        addLog(`Updated Priority: ${this.selectedPriority}`);

        queryParams._set.priority = this.selectedPriority;

        // insert due date
        if (
          (this.selectedPriority == 'Critical' ||
            this.selectedPriority == 'High') &&
          !this.isAjith
        ) {
          if (!this.selectedDueDate) {
            this.message.error('Please Select Due Date');
            return;
          }

          // insert activiy
          addLog('Updated Due Date');

          queryParams._set.due_date = this.selectedDueDate;
        }
      }

      // insert estimated date
      if (this.selectedEstDate) {
        queryParams._set.estimated_date = this.selectedEstDate;

        // insert activiy
        addLog('Updated Estimated Date');
      }
      // insert status and is close
      if (this.selectedStatus) {
        queryParams._set.status = this.selectedStatus;
        // insert activiy
        addLog(`Updated Status: ${this.selectedStatus}`);
      }

      // insert assigned to
      if (this.selectedValue) {
        queryParams._set.assigned_to = <any>this.selectedValue.external_id;

        // insert activiy
        addLog(`Assigned to : ${this.selectedValue.name}`);
      }

      // insert link
      if (this.selectedLink) {
        queryParams._set.link = this.selectedLink;

        // insert activiy
        addLog('Updated Link');
      }
      //insert billable or not
      if (this.isBillable) {
        queryParams._set.is_billable = this.isBillable;
      }
      //insert billable time
      if (this.billable_time) {
        queryParams._set.billable_time = this.billable_time;
      }
      //insert action
      if (this.selectedAction) {
        if (this.selectedAction == 'Closed') {
          queryParams._set.is_closed = true;

          // insert activiy
          addLog('Task Closed');
        } else {
          // insert activiy
          addLog(`Updated Action: ${this.selectedAction}`);
        }
        queryParams._set.action = this.selectedAction;
      }
      if (this.selectedAction == 'Website_Launch') {
        this.graphql
          .websiteLaunch(this.taskData.project_id, this.userData.external_id)
          .subscribe((data) => {});
      }
      console.log(queryParams);

      this.graphql.updateTask(queryParams, this.id).subscribe(({ data }) => {
        this.taskData = data.update_task_manager_tasks.returning[0];
      });

      this.body = {
        projectId: this.taskData.project_id,
        body: {
          name: this.userData.name,
          project: this.project,
          task: this.taskData.title,
          log: log[0].activity_name,
          projectId: this.taskData.project_id,
          taskId: this.id,
        },
      };
      this.auth.sendMail(this.body).subscribe(
        (data) => {
          console.log(data);
        },
        ({ error }) => {
          console.log(error);
        }
      );

      this.graphql.createLog(log).subscribe((data) => {});
    }
  }
  ngOnDestroy(): void {
    this.taskSubscription.unsubscribe();
  }
}
