import { Component, OnInit } from '@angular/core';
import { GraphqlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.css'],
})
export class RejectedComponent implements OnInit {
  rejected = new Array();
  constructor(private graphql: GraphqlService) {}

  ngOnInit(): void {
    this.graphql.getRejected().subscribe((data) => {
      this.rejected = data.data.task_manager_projects;
    });
  }
}
