import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/interfaces/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  userData!:User;
  userRole!:string;
  isAthul!:Boolean;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.userData = <User>JSON.parse(localStorage.getItem('user')!);
    this.userRole=this.userData.role;
    if(this.userData.external_id==environment.ATHUL_ID){
      this.isAthul=true;
    }
  }

  // clear local storage and redirect to login
  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
