<nz-layout style="height: auto">
  <nz-sider
  style="min-height: 100vh;"
    ><app-sidenav class="sidenav"></app-sidenav
    ><img
      routerLink="home"
      class="logo"
      src="../../../assets/images/logo.png"
      alt=""
  /></nz-sider>
  <nz-layout>
    <nz-header style="height: 87px"><app-navbar></app-navbar></nz-header>
    <nz-content
      ><router-outlet style="position: relative"></router-outlet
    ></nz-content>
    <!-- <nz-footer
      style="background-color: rgb(0, 21, 41); color: white; z-index: 100"
      >copyright@beetlebrandingstudio 2022
    </nz-footer> -->
  </nz-layout>
</nz-layout>
