<nav id="nav" class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <!-- <span class="navbar-toggler-icon"></span> -->
      
      <img id="menu" src="../../../assets/images/menu.png" alt="" />
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" routerLink="home">Home</a>
        </li>
        <li *ngIf="userRole == 'ADMIN'" class="nav-item">
          <a class="nav-link" routerLink="add-employee">Add Employee</a>
        </li>
        <li *ngIf="userRole == 'ADMIN' || isAthul" class="nav-item">
          <a class="nav-link" routerLink="add-client">{{
            isAthul ? "Add Employee" : "Add Client"
          }}</a>
        </li>
        <li *ngIf="userRole != 'BEETLER'" class="nav-item">
          <a class="nav-link" routerLink="add-project">Create Project</a>
        </li>
      </ul>
      <form class="d-flex">
        <span nz-tooltip nzTooltipPlacement="bottomLeft" nzTooltipTitle="View Profile"><nz-avatar
          routerLink="profile"
          class="my-3 mx-3"
          id="profile"
          nzIcon="user"
        ></nz-avatar></span>
        
        <a id="logout" class="nav-link" (click)="logout()">Logout</a>
      </form>
    </div>
  </div>
</nav>
